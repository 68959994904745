import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IconClear from '@/assets/icons/icon-clear.vue';
import AppButtonWrapper from '../AppButtonWrapper/AppButtonWrapper.vue';

interface Props {
  hasErrors?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppClearIcon',
  props: {
    hasErrors: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppButtonWrapper, {
    class: _normalizeClass(['clear-icon', { 'clear-icon--error': _ctx.hasErrors }]),
    "data-testid": "app-clear-icon"
  }, {
    default: _withCtx(() => [
      _createVNode(IconClear, { class: "icon-clear" })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})