import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "app-countdown__tooltip" }
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['app-countdown__label'])
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  class: "app-countdown__progress__text",
  "data-testid": "app-countdown-progress-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTooltip = _resolveComponent("AppTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-countdown', { 'app-countdown--badge': _ctx.badge }])
  }, [
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_AppTooltip, {
          key: 0,
          anchor: "top middle",
          self: "bottom middle",
          offset: [10, 10],
          "max-width": "200px"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.leftDays), 1),
              (_ctx.tooltipText !== '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.tooltipText), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.badge ? _ctx.badgeLabel : _ctx.leftDays), 1),
    _createElementVNode("div", {
      "data-testid": "app-countdown-progress-container",
      class: _normalizeClass(['app-countdown__progress__container', _ctx.getChargeColor]),
      style: _normalizeStyle({
        width: `${_ctx.percentualChallange}%`,
      })
    }, null, 6)
  ], 2))
}