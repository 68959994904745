<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, watch } from 'vue';
import { PasswordErrors, Validation } from '@/shared/types/components';
import { validateRule } from '@/shared/helpers/password/changePasswordValidator/changePasswordValidator';
import AppLabel from '../AppLabel/AppLabel.vue';
import AppInput from '../AppInput/AppInput.vue';
import AppPasswordIcon from '../AppPasswordIcon/AppPasswordIcon.vue';
import AppValidationItem from '../AppValidationItem/AppValidationItem.vue';

enum PasswordType {
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
}

interface Props {
  errors: PasswordErrors;
  password: string;
  passwordConfirmation: string;
}

interface Emits {
  (e: 'update:errors', newModelValue: PasswordErrors): void;
  (e: 'update:password', newModelValue: string): void;
  (e: 'update:passwordConfirmation', newModelValue: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const errors = computed<PasswordErrors>({
  get(): PasswordErrors {
    return props.errors;
  },

  set(newModelValue: PasswordErrors): void {
    emit('update:errors', newModelValue);
  },
});

const modelPassword = computed<string>({
  get(): string {
    return props.password;
  },

  set(newModelValue: string): void {
    emit('update:password', newModelValue);
  },
});

const modelPasswordConfirmation = computed<string>({
  get(): string {
    return props.passwordConfirmation;
  },

  set(newModelValue: string): void {
    emit('update:passwordConfirmation', newModelValue);
  },
});

const { t } = useI18n();

const state = reactive({
  showValidations: false,
  showPassword: {
    password: false,
    passwordConfirmation: false,
  },
});

const validations: Validation[] = [
  {
    text: t('changePassword.validate.minimumCharacters'),
    state: false,
    validation: /^[\w\W]{12,64}$/,
  },
  {
    text: t('changePassword.validate.uppercase'),
    state: false,
    validation: /(?=.*?[A-Z])(?=.*?[a-z])/,
  },
  {
    text: t('changePassword.validate.numberAndSpecialChar'),
    state: false,
    validation: /(?=.*?[0-9])(?=.*?[-@#$%^&*_+=[\]{}|\\:',?`~"()/!<>])/,
  },
  {
    text: t('changePassword.validate.consecutiveCharacters'),
    state: false,
    validation: /^(?!.*(.)\1).+$/,
  },
];

const getButtonPasswordType = (showPassword: boolean): string =>
  showPassword ? 'text' : 'password';

const togglePassword = (passwordType: PasswordType): void => {
  state.showPassword[passwordType] = !state.showPassword[passwordType];
};

const resetErrors = (): void => {
  errors.value.password = '';
  errors.value.passwordConfirmation = '';
};

watch(modelPassword, (newModelPasswordValue: string) => {
  resetErrors();
  validations.forEach((validation, index) => {
    validations[index].state = validateRule(
      newModelPasswordValue,
      validation.validation,
    );
  });
});

watch(modelPasswordConfirmation, () => {
  resetErrors();
});

defineExpose({
  modelPassword,
  modelPasswordConfirmation,
  state,
  getButtonPasswordType,
  togglePassword,
  resetErrors,
  validations,
  PasswordType,
});
</script>

<template>
  <div class="new-password">
    <div>
      <AppLabel
        class="new-password__label"
        :label="t('changePassword.newPassword')"
      />

      <AppInput
        v-model="modelPassword"
        name="password"
        :maxlength="64"
        :format-numbers="false"
        :error="errors.password"
        :placeholder="$t('changePassword.placeholders.newPassword')"
        :type="getButtonPasswordType(state.showPassword.password)"
        @focus="state.showValidations = true"
      >
        <template #append>
          <AppPasswordIcon
            :password-has-errors="!!errors.password"
            :show-password="state.showPassword.password"
            @click="togglePassword(PasswordType.password)"
          />
        </template>
      </AppInput>
    </div>

    <section v-if="state.showValidations" class="new-password__validations">
      <b class="new-password__validations-title">
        {{ t('changePassword.mustContain') }}
      </b>
      <div class="new-password__validations-list">
        <AppValidationItem
          v-for="validation in validations"
          :key="validation.text"
          :state="validation.state"
          :text="validation.text"
        />
      </div>
    </section>

    <div>
      <AppLabel
        class="new-password__label"
        :label="t('changePassword.confirmPassword')"
      />
      <AppInput
        v-model="modelPasswordConfirmation"
        name="password"
        :maxlength="64"
        :format-numbers="false"
        :error="errors.passwordConfirmation"
        :type="getButtonPasswordType(state.showPassword.passwordConfirmation)"
        :placeholder="$t('changePassword.placeholders.newPasswordConfirmation')"
      >
        <template #append>
          <AppPasswordIcon
            :password-has-errors="!!errors.passwordConfirmation"
            :show-password="state.showPassword.passwordConfirmation"
            @click="togglePassword(PasswordType.passwordConfirmation)"
          />
        </template>
      </AppInput>
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-password {
  display: flex;
  flex-direction: column;
}

.new-password__label {
  font-weight: 700;
  font-size: 14px;
  color: $gray-800;
  padding: 0;
}

.new-password__validations {
  padding: 16px;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 4px;
  box-shadow: $card-shadow;
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 16px;
}

.new-password__validations-title {
  font-size: 14px;
  color: $gray-800;
}

.new-password__validations-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
