import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "app-nuvo-container",
  "data-testid": "app-nuvo-container"
}
const _hoisted_2 = {
  class: "app-nuvo-container__header",
  "data-testid": "app-nuvo-container-header"
}
const _hoisted_3 = {
  class: "app-nuvo-container__body",
  "data-testid": "app-nuvo-container-body-content"
}

import IconClear from '@/assets/icons/icon-clear.vue';
import { AppButton } from '@/plugins/commons';
import i18n from '@/plugins/i18n/i18n';
import { FileType, NuvoImportActionLevel } from '@/shared/types/generic';
import {
  ColumnAPI,
  ColumnHooks,
  DataHandlerHeaderStep,
  NuvoImporter,
  OnEntryChange,
  OnEntryInit,
  OnResults,
} from 'nuvo-vuejs';
import { computed, onMounted, ref } from 'vue';
import AppInnerLoading from '../AppInnerLoading/AppInnerLoading.vue';
import AppModalDiscard from '../AppModalDiscard/AppModalDiscard.vue';
import NuvoStyles from './NuvoStyles';

interface Props {
  columnHooks: ColumnHooks;
  onEntryInit: OnEntryInit;
  onEntryChange: OnEntryChange;
  onResults: OnResults;
  headerStep: DataHandlerHeaderStep;
  columns: ColumnAPI[];
  language: string;
  fileName: string;
  completeImportAction?: NuvoImportActionLevel;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppNuvoContainer',
  props: {
    columnHooks: {},
    onEntryInit: { type: Function },
    onEntryChange: { type: Function },
    onResults: { type: Function },
    headerStep: { type: Function },
    columns: {},
    language: {},
    fileName: {},
    completeImportAction: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const licenseKey = ref(process.env.VUE_APP_NUVO_KEY || '');
const showConfirmChangesModal = ref(false);
const isLoading = ref(true);

const i18nOverrides = computed(() => {
  return JSON.parse(
    JSON.stringify(i18n.global.getLocaleMessage(props.language)),
  );
});

const settings = computed(() => ({
  developerMode: process.env.VUE_APP_NUVO_DEV === 'enabled',
  modal: false,
  maxEntries: 50000,
  identifier: props.fileName,
  i18nOverrides: i18nOverrides.value,
  columns: JSON.parse(JSON.stringify(props.columns)),
  style: NuvoStyles,
  inputTypes: [FileType.CSV, FileType.XLSX, FileType.XLS],
  completeImportAction: props.completeImportAction,
}));

const dataHandler = computed(() => ({
  headerStep: props.headerStep,
}));

function toggleConfirmChangesModal(): void {
  showConfirmChangesModal.value = !showConfirmChangesModal.value;
}

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 5000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.language)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "app-nuvo-overlay",
          "data-testid": "app-nuvo-overlay",
          onClick: _withModifiers(toggleConfirmChangesModal, ["self"]),
          onKeydown: _withModifiers(toggleConfirmChangesModal, ["self"])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(AppButton), {
                type: "tertiary",
                size: "S",
                "is-button-icon": "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
              }, {
                default: _withCtx(() => [
                  _createVNode(IconClear)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(AppInnerLoading, {
                class: "app-nuvo-container__body__loader",
                size: "50px",
                showing: isLoading.value
              }, null, 8, ["showing"]),
              _createVNode(_unref(NuvoImporter), {
                "license-key": licenseKey.value,
                settings: settings.value,
                "column-hooks": _ctx.columnHooks,
                "on-entry-init": _ctx.onEntryInit,
                "on-entry-change": _ctx.onEntryChange,
                "data-handler": dataHandler.value,
                "on-results": _ctx.onResults,
                text: _ctx.$t('txt_select_files'),
                "data-testid": "app-nuvo-importer"
              }, null, 8, ["license-key", "settings", "column-hooks", "on-entry-init", "on-entry-change", "data-handler", "on-results", "text"])
            ]),
            _createVNode(AppModalDiscard, {
              modelValue: showConfirmChangesModal.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showConfirmChangesModal).value = $event)),
              onDiscard: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
            }, null, 8, ["modelValue"])
          ])
        ], 32))
      : _createCommentVNode("", true)
  ]))
}
}

})