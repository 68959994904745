import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  isCoompetitionOrRace: boolean;
  position?: number;
  isChangePositionColor?: boolean;
  isTie?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppPosition',
  props: {
    isCoompetitionOrRace: { type: Boolean },
    position: {},
    isChangePositionColor: { type: Boolean },
    isTie: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const { t } = useI18n();

const classPosition = computed<string>(() => {
  if (props.isTie) {
    return '';
  }

  return props.isCoompetitionOrRace && props.position && props.position <= 3
    ? 'app-position--podium'
    : '';
});

const userPosition = computed<string>(() => {
  if (!props.position) {
    return '-';
  }
  return props.isTie ? t('leaderboard.tie') : `${props.position}`;
});

const classTopThreePositions = computed<string>(() => {
  if (
    !props.position ||
    props.position > 3 ||
    !props.isCoompetitionOrRace ||
    props.isTie
  ) {
    return '';
  }
  return classPosition.value === 'app-position--podium' && props.position === 1
    ? 'app-position--first'
    : 'app-position--second-third';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-position', classPosition.value, classTopThreePositions.value])
  }, _toDisplayString(userPosition.value), 3))
}
}

})