import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-creation-layout" }
const _hoisted_2 = {
  class: "app-creation-layout__body",
  "data-testid": "app-creation-layout-body"
}
const _hoisted_3 = {
  class: "app-creation-layout__content",
  "data-testid": "app-creation-layout-content"
}
const _hoisted_4 = {
  class: "app-creation-layout__header",
  "data-testid": "app-creation-layout-header"
}
const _hoisted_5 = {
  class: "app-creation-layout__main",
  "data-testid": "app-creation-layout-main"
}
const _hoisted_6 = {
  key: 0,
  class: "app-creation-layout__sidebar",
  "data-testid": "app-creation-layout-sidebar"
}
const _hoisted_7 = {
  class: "app-creation-layout__footer",
  "data-testid": "app-creation-layout-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      (_ctx.sidebar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ]))
}