import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app-accordion__container",
  "data-testid": "app-accordion-container"
}
const _hoisted_2 = {
  class: "app-accordion__header-content",
  "data-testid": "app-accordion-header-content"
}
const _hoisted_3 = {
  class: "app-accordion__content",
  "data-testid": "app-accordion-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowDown = _resolveComponent("IconArrowDown")!
  const _component_AppSeparator = _resolveComponent("AppSeparator")!
  const _component_AppCollapsible = _resolveComponent("AppCollapsible")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'app-accordion__header',
        { 'app-accordion__header--bold': _ctx.expandedQuestion },
      ]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerExpandedQuestion && _ctx.handlerExpandedQuestion(...args))),
      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlerExpandedQuestion && _ctx.handlerExpandedQuestion(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ]),
      _createVNode(_component_IconArrowDown, {
        class: _normalizeClass([
          'app-accordion__arrow',
          { 'app-accordion__arrow--active': _ctx.expandedQuestion },
        ])
      }, null, 8, ["class"])
    ], 34),
    _createVNode(_component_AppCollapsible, { expanded: _ctx.expandedQuestion }, {
      default: _withCtx(() => [
        _createVNode(_component_AppSeparator, { class: "app-accordion__separator" }),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["expanded"])
  ]))
}