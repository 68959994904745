import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import IconPasswordEyeClosed from '@/assets/icons/icon-password-eye-closed.vue';
import IconPasswordEye from '@/assets/icons/icon-password-eye.vue';
import { computed } from 'vue';
import AppButtonWrapper from '../AppButtonWrapper/AppButtonWrapper.vue';

interface Props {
  passwordHasErrors?: boolean;
  showPassword: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppPasswordIcon',
  props: {
    passwordHasErrors: { type: Boolean },
    showPassword: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const showPasswordIcon = computed(() => {
  return props.showPassword ? IconPasswordEyeClosed : IconPasswordEye;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppButtonWrapper, {
    class: _normalizeClass(['password-icon', { 'password-icon--error': _ctx.passwordHasErrors }])
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(showPasswordIcon.value)))
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})