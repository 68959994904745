import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-wizard-header" }

import AppBreadcrumbs from '@/components/app/AppBreadcrumbs/AppBreadcrumbs.vue';
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AppWizardHeader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppBreadcrumbs),
    _createVNode(AppSeparator, { class: "app-wizard-header__separator" })
  ]))
}
}

})