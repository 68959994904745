import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import {
  matFullscreen,
  matFullscreenExit,
} from '@quasar/extras/material-icons';
import { AppButton } from '@/plugins/commons';

interface Props {
  active: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppButtonExpand',
  props: {
    active: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const icon = computed<string>(() => {
  return props.active ? matFullscreenExit : matFullscreen;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppButton), _mergeProps(_ctx.$attrs, {
    class: [
      'app-button-expand',
      {
        'app-button-expand--active': _ctx.active,
      },
    ],
    type: "tertiary",
    "is-button-icon": ""
  }), {
    default: _withCtx(() => [
      _createVNode(AppIcon, { name: icon.value }, null, 8, ["name"])
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})