<template>
  <teleport to="body">
    <div
      v-if="language"
      class="app-nuvo-overlay"
      data-testid="app-nuvo-overlay"
      @click.self="toggleConfirmChangesModal"
      @keydown.self="toggleConfirmChangesModal"
    >
      <div class="app-nuvo-container" data-testid="app-nuvo-container">
        <div
          class="app-nuvo-container__header"
          data-testid="app-nuvo-container-header"
        >
          <AppButton
            type="tertiary"
            size="S"
            is-button-icon
            @click="emit('close')"
          >
            <IconClear />
          </AppButton>
        </div>
        <div
          class="app-nuvo-container__body"
          data-testid="app-nuvo-container-body-content"
        >
          <AppInnerLoading
            class="app-nuvo-container__body__loader"
            size="50px"
            :showing="isLoading"
          />
          <NuvoImporter
            :license-key="licenseKey"
            :settings="settings"
            :column-hooks="columnHooks"
            :on-entry-init="onEntryInit"
            :on-entry-change="onEntryChange"
            :data-handler="dataHandler"
            :on-results="onResults"
            :text="$t('txt_select_files')"
            data-testid="app-nuvo-importer"
          />
        </div>

        <AppModalDiscard
          v-model="showConfirmChangesModal"
          @discard="emit('close')"
        />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import IconClear from '@/assets/icons/icon-clear.vue';
import { AppButton } from '@/plugins/commons';
import i18n from '@/plugins/i18n/i18n';
import { FileType, NuvoImportActionLevel } from '@/shared/types/generic';
import {
  ColumnAPI,
  ColumnHooks,
  DataHandlerHeaderStep,
  NuvoImporter,
  OnEntryChange,
  OnEntryInit,
  OnResults,
} from 'nuvo-vuejs';
import { computed, onMounted, ref } from 'vue';
import AppInnerLoading from '../AppInnerLoading/AppInnerLoading.vue';
import AppModalDiscard from '../AppModalDiscard/AppModalDiscard.vue';
import NuvoStyles from './NuvoStyles';

interface Props {
  columnHooks: ColumnHooks;
  onEntryInit: OnEntryInit;
  onEntryChange: OnEntryChange;
  onResults: OnResults;
  headerStep: DataHandlerHeaderStep;
  columns: ColumnAPI[];
  language: string;
  fileName: string;
  completeImportAction?: NuvoImportActionLevel;
}
const props = defineProps<Props>();

const emit = defineEmits(['close']);

const licenseKey = ref(process.env.VUE_APP_NUVO_KEY || '');
const showConfirmChangesModal = ref(false);
const isLoading = ref(true);

const i18nOverrides = computed(() => {
  return JSON.parse(
    JSON.stringify(i18n.global.getLocaleMessage(props.language)),
  );
});

const settings = computed(() => ({
  developerMode: process.env.VUE_APP_NUVO_DEV === 'enabled',
  modal: false,
  maxEntries: 50000,
  identifier: props.fileName,
  i18nOverrides: i18nOverrides.value,
  columns: JSON.parse(JSON.stringify(props.columns)),
  style: NuvoStyles,
  inputTypes: [FileType.CSV, FileType.XLSX, FileType.XLS],
  completeImportAction: props.completeImportAction,
}));

const dataHandler = computed(() => ({
  headerStep: props.headerStep,
}));

function toggleConfirmChangesModal(): void {
  showConfirmChangesModal.value = !showConfirmChangesModal.value;
}

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 5000);
});
</script>

<style scoped lang="scss">
.app-nuvo-overlay {
  position: fixed;
  inset: 0;
  z-index: 5000;
  background: $modal-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-nuvo-container {
  width: 80%;
  background-color: $white;
  border-radius: 16px;
  max-width: 1730px;
  margin: 16px;
  transform: translate(0);
}

.app-nuvo-container__header {
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.app-nuvo-container__body {
  position: relative;
  max-height: 92vh !important;
  border-radius: 16px;
  overflow: auto;
}
</style>

<style lang="scss">
.nuvo-tailwind .bg-black {
  background: $modal-overlay !important;
}

.nuvo-tailwind .flex {
  flex-wrap: nowrap;
}

.nuvo-tailwind .inline-block.inline-flex {
  display: inline-flex !important;
}

.nuvo-tailwind .data-review .handsontable th.checked-row .row-header-checkbox,
.nuvo-tailwind .data-review .handsontable th.hover-row .row-header-checkbox {
  display: block !important;
}

.nuvo-tailwind .data-review .row-header-checkbox {
  display: none !important;
}
</style>
