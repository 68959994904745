import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-text-editor" }
const _hoisted_2 = { class: "app-text-editor__counter" }

import { reactive, watch } from 'vue';

interface Props {
  maxlength: number;
  placeholder?: string;
}

interface State {
  textLength: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTextEditor',
  props: /*@__PURE__*/_mergeModels({
    maxlength: {},
    placeholder: {}
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const editorText = _useModel<string>(__props, "modelValue");

const toolbarOperations = [
  ['bold', 'italic', 'underline', 'unordered', 'ordered'],
];

const props = __props;
const state = reactive<State>({
  textLength: 0,
});

watch(
  () => editorText.value,
  (newValue: string | undefined, oldValue: string | undefined) => {
    if (newValue) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(newValue, 'text/html');
      const isTextLongerThanMax =
        (doc.body.textContent?.length || 0) > props.maxlength && oldValue;

      if (isTextLongerThanMax) {
        editorText.value = oldValue;
        return;
      }
      state.textLength = doc.body.textContent?.length || 0;
    }
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_q_editor = _resolveComponent("q-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_editor, {
      modelValue: editorText.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editorText).value = $event)),
      toolbar: toolbarOperations,
      "min-height": "64px",
      "max-height": "64px",
      placeholder: props.placeholder || undefined
    }, null, 8, ["modelValue", "placeholder"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(state.textLength) + "/" + _toDisplayString(props.maxlength), 1)
  ]))
}
}

})