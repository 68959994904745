import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ftx__container" }
const _hoisted_2 = { class: "ftx-slide__buttons" }

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { AppButton } from '@/plugins/commons';
import FTXSlideComponent from '@/components/ftx/FTXSlide/FTXSlide.vue';
import AppBulletSlider from '@/components/app/AppBulletSlider/AppBulletSlider.vue';
import useFTXList from '@/composables/useFTXList/useFTXList';
import { FTXPage, FTXSlide, FTXView } from '@/shared/types/ftx';
import useProfileStore from '@/store/profile/useProfileStore';
import useSteps from '@/composables/useSteps/useSteps';
import { StepsButtonsLabel } from '@/shared/types/generic';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import { PostHogEvents } from '@/shared/types/posthog';
import ChallengesFTX from '@/assets/icons/ftx-challenges.vue';
import MeasureFTX from '@/assets/icons/ftx-measure.vue';
import useDriver from '@/composables/useDriverJs/useDriverJs';
import PrizesFTX from '@/assets/icons/ftx-prizes.vue';
import useNavMenuData from '@/composables/useNavMenuData/useNavMenuData';


export default /*@__PURE__*/_defineComponent({
  __name: 'FirstTimeExperience',
  setup(__props) {

const { t } = useI18n();

const profileStore = useProfileStore();

const { postHogEvent } = usePostHogEvents();

const state = reactive<FTXView>({ show: true });

const slidesList: FTXSlide[] = [
  {
    key: FTXPage.WELCOME,
    title: t('ftx.welcome.title', [profileStore.userProfile?.user.username]),
    description: t('ftx.welcome.description'),
  },
  {
    key: FTXPage.CHALLENGES,
    title: t('ftx.challenges.title'),
    imageSlide: ChallengesFTX,
    imageDescription: 'onboarding challenges page',
    description: t('ftx.challenges.description'),
  },
  {
    key: FTXPage.PRIZES,
    title: t('ftx.rewards.title'),
    imageSlide: PrizesFTX,
    imageDescription: 'onboarding rewards page',
    description: t('ftx.rewards.description'),
  },
  {
    key: FTXPage.SURVEYS,
    title: t('ftx.measure.title'),
    imageSlide: MeasureFTX,
    imageDescription: 'onboarding measure page',
    description: t('ftx.measure.description'),
  },
];

const { slidesContent } = useFTXList(slidesList);

const buttonsLabel: StepsButtonsLabel = {
  last: t('labels.common.getStarted'),
  next: t('labels.common.next'),
};

const { stepIndex, isLastStep, nextStep, labelNextAndLastButton } = useSteps(
  slidesContent.length,
  buttonsLabel,
);

const updateFTXAndGoToHome = async () => {
  await profileStore.updateShowFtx(false);

  state.show = false;

  const { driverSteps } = useNavMenuData();

  const driverJsJoyrideButtons = {
    next: t('labels.common.next'),
    previous: t('labels.common.previous'),
    done: t('labels.common.finish'),
  };

  useDriver(driverSteps.value, driverJsJoyrideButtons);
};

const nextSlide = () => {
  if (isLastStep.value) {
    updateFTXAndGoToHome();
  } else {
    nextStep();
  }
};

const dismissFTX = () => {
  updateFTXAndGoToHome();

  postHogEvent(PostHogEvents.FTX_DISMISS_INITIAL_MANAGER);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    modelValue: state.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.show) = $event)),
    width: "600px",
    class: "ftx-background",
    "show-footer": false,
    "show-header": false,
    persistent: ""
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FTXSlideComponent, {
          slide: _unref(slidesContent)[_unref(stepIndex)]
        }, null, 8, ["slide"]),
        _createVNode(AppBulletSlider, {
          "total-bullets": _unref(slidesContent).length,
          "highlighted-index": _unref(stepIndex) + 1
        }, null, 8, ["total-bullets", "highlighted-index"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(AppButton), {
            type: "primary",
            size: "L",
            rounded: "",
            label: _unref(labelNextAndLastButton),
            onClick: nextSlide
          }, null, 8, ["label"]),
          (!_unref(isLastStep))
            ? (_openBlock(), _createBlock(_unref(AppButton), {
                key: 0,
                type: "tertiary",
                size: "L",
                rounded: "",
                label: _unref(t)('labels.common.dismiss'),
                onClick: dismissFTX
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})