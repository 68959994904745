import {
  addDays,
  differenceInDays,
  differenceInMonths,
  format,
  getHours,
  getMinutes,
  getWeekOfMonth,
  intervalToDuration,
  isSameMonth,
  parse,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import i18n from '@/plugins/i18n/i18n';
import { DatePeriod, Locale } from '@/shared/types/generic';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  isSameDayTimezone,
  isSameWeekTimezone,
  isSameYearTimezone,
  isThisWeekTimezone,
  isThisYearTimezone,
  isTodayTimezone,
  isYesterdayTimezone,
} from '@/shared/helpers/timezones/dateFns/dateFnsTimezones';
import { DashboardMetricChartDateInterval } from '@/shared/types/dashboard';
import getDateFnsLang from '../getDateFnsLang/getDateFnsLang';

export function parseDateWithTimezone(
  date: string,
  mask: string,
  timezone: string,
  referenceDate = new Date(),
) {
  const zonedDate = utcToZonedTime(referenceDate, timezone);
  const parsedDate = parse(date, mask, zonedDate);
  return zonedTimeToUtc(parsedDate, timezone);
}
export function parseDate(
  date: string,
  mask: string,
  referenceDate = new Date(),
) {
  return parse(date, mask, referenceDate);
}
export function formatDateToString(
  date: Date | number,
  mask: string,
  timezone: string,
) {
  if (!timezone) {
    return format(date, mask, {
      locale: getDateFnsLang(i18n.global.locale.value as Locale),
    });
  }
  return formatInTimeZone(date, timezone, mask, {
    locale: getDateFnsLang(i18n.global.locale.value as Locale),
  });
}

export const formatDateDashboard = (
  date: number,
  datePreset: DatePeriod,
  timezone: string,
): string => {
  let mask = 'dashboard.dates.gantt';

  if (isThisYearTimezone(date, timezone)) mask += '.sameYear';

  switch (datePreset) {
    case DatePeriod.WEEK:
      mask += '.week';
      break;
    case DatePeriod.MONTH:
      mask += '.month';
      break;
    case DatePeriod.QUARTER:
      mask += '.year';
      break;
    case DatePeriod.YEAR:
    default:
      mask += '.year';
      break;
  }

  return formatDateToString(date, i18n.global.t(mask), timezone);
};

export const displayFormattedTime = (
  date: number,
  timezone: string,
): string => {
  return formatDateToString(
    date,
    i18n.global.t('common.format.date.sameWeekTable.time'),
    timezone,
  );
};

export const displayFormattedDate = (
  date: number,
  timezone: string,
): string => {
  if (isThisWeekTimezone(date, timezone)) {
    if (isTodayTimezone(date, timezone)) {
      return `${i18n.global.t('common.dates.today')}`;
    }

    if (isYesterdayTimezone(date, timezone)) {
      return `${i18n.global.t('common.dates.yesterday')}`;
    }

    return `${formatDateToString(
      date,
      i18n.global.t('common.format.date.sameWeekTable.date'),
      timezone,
    )}`;
  }

  if (isThisYearTimezone(date, timezone)) {
    return formatDateToString(
      date,
      i18n.global.t('common.format.date.sameYearTable'),
      timezone,
    );
  }

  return formatDateToString(
    date,
    i18n.global.t('common.format.date.defaultTable'),
    timezone,
  );
};

export const formatDate = (date: number, timezone: string): string => {
  if (isThisWeekTimezone(date, timezone)) {
    if (isTodayTimezone(date, timezone)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${i18n.global.t('common.dates.today')}${formatDateToString(
        date,
        i18n.global.t('common.format.date.sameWeek.today'),
        timezone,
      )}`;
    }

    if (isYesterdayTimezone(date, timezone)) {
      return `${i18n.global.t('common.dates.yesterday')}${formatDateToString(
        date,
        i18n.global.t('common.format.date.sameWeek.yesterday'),
        timezone,
      )}`;
    }

    return `${formatDateToString(
      date,
      i18n.global.t('common.format.date.sameWeek.default'),
      timezone,
    )}`;
  }

  if (isThisYearTimezone(date, timezone)) {
    return formatDateToString(
      date,
      i18n.global.t('common.format.date.sameYear'),
      timezone,
    );
  }

  return formatDateToString(
    date,
    i18n.global.t('common.format.date.default'),
    timezone,
  );
};

export const getWeekOfMonthWithOffset = (date: number) => {
  const startOfWeekOfFirstOfMonth = startOfWeek(startOfMonth(date), {
    weekStartsOn: 1,
  });
  const offset = isSameMonth(date, startOfWeekOfFirstOfMonth) ? 0 : 1;
  return getWeekOfMonth(date, { weekStartsOn: 1 }) - offset;
};

export const formatDateDashboardMetric = (
  date: number,
  datePreset: DashboardMetricChartDateInterval,
  timezone: string,
): string => {
  let mask = 'dashboard.dates.metricChart';

  if (isThisYearTimezone(date, timezone)) mask += '.sameYear';

  switch (datePreset) {
    case DashboardMetricChartDateInterval.HOUR:
      if (getHours(date) === 0 && getMinutes(date) === 0) {
        mask += '.day';
      } else {
        mask += '.hour';
      }
      break;
    case DashboardMetricChartDateInterval.DAY:
      mask += '.day';
      break;
    case DashboardMetricChartDateInterval.WEEK:
      mask += '.week';
      return `${formatDateToString(
        date,
        i18n.global.t(mask),
        timezone,
      )}, ${i18n.global
        .t('common.dates.week')
        .toLowerCase()} ${getWeekOfMonthWithOffset(date)}`;
    case DashboardMetricChartDateInterval.MONTH:
    default:
      mask += '.month';
      break;
  }

  return formatDateToString(date, i18n.global.t(mask), timezone);
};

export function formatTime(time: number | undefined, timezone: string): string {
  if (time) {
    return formatDate(time, timezone);
  }
  return '-';
}

export enum DateFormat {
  DATE = 'DATE',
  TIME = 'TIME',
}

export function formatDateTable(
  time: number | undefined,
  timezone: string,
  type: DateFormat,
): string {
  if (time) {
    if (type === DateFormat.DATE) {
      return displayFormattedDate(time, timezone);
    }
    return displayFormattedTime(time, timezone);
  }
  return '-';
}

export const timeDifference = (time1: number, time2: number): string => {
  const date1 = new Date(time1);
  const date2 = new Date(time2);
  const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const result = [];
  let future = addDays(date1, diffDays);

  const months = differenceInMonths(future, date1);
  if (months > 0) {
    result.push(`${i18n.global.t('common.initial.months', [months], months)}`);

    future = subMonths(future, months);
  }

  const days = differenceInDays(future, time1);
  if (days > 0) {
    result.push(`${i18n.global.t('common.initial.days', [days], days)}`);
  }

  return result.join(', ');
};

export const formatRangeFutureTime = (
  startTime: number,
  endTime: number,
  timezone: string,
): string => {
  const sameDay = isSameDayTimezone(startTime, endTime, timezone);
  const sameWeek = isSameWeekTimezone(startTime, endTime, timezone);
  const sameYear = isSameYearTimezone(startTime, endTime, timezone);
  const endPartOfDay = formatDateToString(endTime, 'aa', timezone);
  const startPartOfDay = formatDateToString(startTime, 'aa', timezone);

  if (sameDay && startPartOfDay === endPartOfDay) {
    return `${formatDateToString(
      startTime,
      i18n.global.t('common.format.time.partialDay'),
      timezone,
    )}–${formatDateToString(
      endTime,
      i18n.global.t('common.format.time.today'),
      timezone,
    )}`;
  }

  const formattedStartTime = formatDateToString(
    startTime,
    i18n.global.t('common.format.time.today'),
    timezone,
  );

  if (sameDay) {
    return `${formattedStartTime}–${formatDateToString(
      endTime,
      i18n.global.t('common.format.time.today'),
      timezone,
    )}`;
  }

  if (sameWeek) {
    return `${formattedStartTime}–${formatDateToString(
      endTime,
      i18n.global.t('common.format.date.sameWeek.default'),
      timezone,
    )}`;
  }

  if (sameYear) {
    return `${formattedStartTime}–${formatDateToString(
      endTime,
      i18n.global.t('common.format.time.sameYear'),
      timezone,
    )}`;
  }

  return `${formattedStartTime}–${formatDateToString(
    endTime,
    i18n.global.t('common.format.time.default'),
    timezone,
  )}`;
};

export const formatCompleteDuration = (
  startTime: number,
  endTime: number,
): string => {
  const completeDuration = intervalToDuration({
    start: startTime,
    end: endTime,
  });

  return Object.entries(completeDuration).reduce((acc, pair) => {
    const value = pair[1];
    const durationUnit = pair[0];

    if (value !== 0) {
      if (acc === '') {
        return `${i18n.global.t(
          `common.initial.${durationUnit}`,
          [value],
          value,
        )}`;
      }

      if (durationUnit !== 'seconds') {
        return `${acc}, ${i18n.global.t(
          `common.initial.${pair[0]}`,
          [value],
          value,
        )}`;
      }
    }

    return acc;
  }, '' as string);
};
