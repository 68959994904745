import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-store-item-status-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTooltip = _resolveComponent("AppTooltip")!
  const _component_AppBadge = _resolveComponent("AppBadge")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.computedClass)
      }, [
        (_ctx.tooltip && _ctx.startDate)
          ? (_openBlock(), _createBlock(_component_AppTooltip, {
              key: 0,
              anchor: "top middle",
              self: "bottom middle",
              offset: [5, 5],
              "max-width": "200px"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getStatusTooltip(
              _ctx.status,
              _ctx.startDate,
              _ctx.endDate,
              _ctx.profileStore?.userProfileSite?.site.timezone.ianaTimezone || '',
            )), 1)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_AppBadge, {
          color: _ctx.getStoreStatusColor(_ctx.status),
          "data-testid": "store-item-status"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`store.filters.status.${_ctx.status}`)), 1)
          ]),
          _: 1
        }, 8, ["color"]),
        (_ctx.showCalendarIcon(_ctx.status, _ctx.endDate))
          ? (_openBlock(), _createBlock(_component_AppIcon, {
              key: 1,
              class: "app-store-item-status-icon",
              name: _ctx.outlinedEvent,
              size: "19px"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}