import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-dropdown-label" }

import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import AppLabel from '@/components/app/AppLabel/AppLabel.vue';

interface Props {
  isDisabled?: boolean;
  label: string;
  tooltipMessage?: string;
}

interface Emits {
  (e: 'click'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDropdownLabel',
  props: {
    isDisabled: { type: Boolean },
    label: {},
    tooltipMessage: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const handleClickEvent = (): void => {
  if (!props.isDisabled) {
    emit('click');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDisabled && _ctx.tooltipMessage)
      ? (_openBlock(), _createBlock(_unref(BaseTooltip), { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tooltipMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(AppLabel, {
      class: _normalizeClass([
        'app-button-dropdown-option',
        {
          'app-button-dropdown-option--disabled': _ctx.isDisabled,
        },
      ]),
      "margin-bottom": "0",
      disabled: _ctx.isDisabled,
      label: _ctx.label,
      onClick: handleClickEvent
    }, null, 8, ["class", "disabled", "label"])
  ]))
}
}

})