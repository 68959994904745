import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from '@/plugins/i18n/i18n';
import Quasar from 'quasar/src/vue-plugin.js';;
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueDomPurifyHTML from 'vue-dompurify-html';

import '@/plugins/echarts/echarts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import quasarUserOptions from './quasar-user-options';
import App from './App.vue';
import router from './router';
import worker from './mocks/browser';
import '@vaibe-github-enterprise/kd-kse-fe-commons/style.css';
import '@/styles/app.scss';

if (process.env.VUE_APP_USE_MOCK_SERVER === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app
  .use(i18n)
  .use(Quasar, quasarUserOptions)
  .use(pinia)
  .use(router)
  .use(VueDomPurifyHTML);

app.config.warnHandler = (msg, vm, trace) => {
  if (msg.includes('Extraneous non-props attributes (is-sidebar-open)')) {
    return;
  }
  if (msg.includes('Extraneous non-emits event listeners (cancel, edit)')) {
    return;
  }
  if (
    msg.includes(
      'Extraneous non-emits event listeners (changeTeam, deleteUsers)',
    )
  ) {
    return;
  }
  if (msg.includes('Extraneous non-props attributes (selected-users)')) {
    return;
  }
  if (msg.includes('Extraneous non-props attributes (previousRoute)')) {
    return;
  }
  // eslint-disable-next-line no-console
  console.warn(msg, trace);
};

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
});
