import { Sites, SitesCount, SitesSimplified } from '@/shared/types/settings';

const createSites = (amount: number): Sites[] =>
  new Array(amount).fill(0).map(() => ({
    name: 'Teste 8',
    description: 'desc',
    reference: '974270d9-7e63-4de6-bbc9-14df3b406720',
    externalReference: 'IDTESTE8',
    city: 'Berlin',
    state: 'Berlin',
    country: 'Germany',
    numberOfUsers: 3,
    timezone: {
      utcOffset: 'UTC+01:00',
      ianaTimezone: 'Europe/Berlin',
    },
    numberOfManagers: 2,
    numberOfOperators: 1,
  }));

const sitesArray = createSites(1);
sitesArray.push({
  name: 'Lisbon Site',
  description: 'desc',
  reference: '38ce1d5f-3bda-4313-a932-4fcdaa59947a',
  externalReference: 'IDTESTE8',
  city: 'Lisbon',
  state: 'Lisbon',
  country: 'Portugal',
  numberOfUsers: 0,
  timezone: {
    utcOffset: 'UTC+01:00',
    ianaTimezone: 'Europe/Berlin',
  },
  numberOfManagers: 0,
  numberOfOperators: 0,
});

export const sitesSimplifiedList: SitesSimplified[] = [
  {
    name: 'Lisbon Site',
    city: 'Lisbon',
    country: 'Portugal',
    reference: '38ce1d5f-3bda-4313-a932-4fcdaa59947a',
    timezone: {
      utcOffset: 'UTC+01:00',
      ianaTimezone: 'Europe/Berlin',
    },
    externalReference: 'SiteId1',
  },
  {
    name: 'Test 2',
    city: 'Teste 2',
    country: 'teste 2',
    reference: 'reference 2',
    timezone: {
      utcOffset: 'UTC+01:00',
      ianaTimezone: 'Europe/Berlin',
    },
    externalReference: 'SiteId2',
  },
  {
    name: 'Test 3',
    city: 'Teste 3',
    country: 'teste 3',
    reference: 'reference 3',
    timezone: {
      utcOffset: 'UTC+01:00',
      ianaTimezone: 'Europe/Berlin',
    },
    externalReference: 'SiteId3',
  },
];

export const sites: Sites[] = sitesArray;

export const allSites: Sites[] = createSites(30).concat(createSites(30));

export const getSites = (page?: number, size?: number) => ({
  metadata: {
    lastUpdated: 1701700090537,
    totalItems: 1,
  },
  results: {
    sites:
      page !== undefined && size !== undefined
        ? sites.slice(page * size, page * size + size)
        : sites,
  },
});

export const getSitesSimplified = () => ({
  metadata: {
    lastUpdated: 1701700090537,
    totalItems: 1,
  },
  results: {
    sites: sitesSimplifiedList,
  },
});

export const getMockedChallengeSites = () => ({
  metadata: {
    totalItems: sites.length,
    lastUpdated: 1595030400000,
  },
  results: allSites,
});

export const getMockedSitesCounters = (): SitesCount => {
  return {
    numberOfSites: getSites().results.sites.length,
  };
};
