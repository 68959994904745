import { useModel as _useModel, useCssVars as _useCssVars, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: "placeholder",
  class: "app-select-hierarchical_placeholder"
}
const _hoisted_2 = { class: "app-select-hierarchical__label-mode" }
const _hoisted_3 = { class: "app-select-hierarchical__label-mode-description" }

import { computed, ref } from 'vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import handleSelectPopupShow from '@/shared/helpers/handleSelectPopupShow/handleSelectPopupShow';
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { AppSelectSize } from '@/shared/types/components';
import { SelectGroupItem } from '@/shared/types/generic';
import QSelect from 'quasar/src/components/select/QSelect.js';;
import { ChallengeFormat } from '@/shared/types/wizard';
import AppSelect from '@/components/app/AppSelect/AppSelect.vue';
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';

interface Props {
  inputDebounce?: number;
  width?: string;
  size?: AppSelectSize;
  placeholder?: string;
  error?: string;
  options: unknown[];
  selectLabel: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSelectHierarchical',
  props: /*@__PURE__*/_mergeModels({
    inputDebounce: { default: 300 },
    width: { default: '296px' },
    size: { default: 'M' },
    placeholder: { default: '' },
    error: { default: '' },
    options: {},
    selectLabel: {}
  }, {
    "modelValue": {
  required: true,
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {

_useCssVars(_ctx => ({
  "e2c51b06": (_ctx.width)
}))

const model = _useModel<string>(__props, "modelValue");

const props = __props;

const qselect = ref<QSelect | null>(null);

const showPlaceholder = computed<boolean>(() => {
  return props.placeholder !== undefined && !model.value;
});

function isItemSelected(item: SelectGroupItem): boolean {
  return item.value === model.value;
}

function hidePopup() {
  qselect.value?.hidePopup();
}

function selectItem(item: SelectGroupItem): void {
  model.value = item.value as ChallengeFormat;
  hidePopup();
}

__expose({
  hidePopup,
  selectItem,
  isItemSelected,
});

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createBlock(AppSelect, _mergeProps(_ctx.$props, {
    ref_key: "qselect",
    ref: qselect,
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    options: _ctx.options,
    "dropdown-icon": _unref(symRoundedKeyboardArrowDown),
    "input-debounce": _ctx.inputDebounce,
    class: [
      'app-select-hierarchical',
      `app-select-hierarchical--${_ctx.size}`,
      { 'app-select-hierarchical--width': _ctx.width },
    ],
    "input-class": "app-select-section__input",
    "popup-content-class": `app-select__popup-content app-select__popup-content-grouped--all`,
    "options-dense": "",
    "emit-value": "",
    borderless: "",
    error: _ctx.error,
    "no-error-icon": "",
    "bottom-slots": "",
    onPopupShow: _cache[1] || (_cache[1] = ($event: any) => (_unref(handleSelectPopupShow)(_ctx.$el, '--appSelectMaxWidth')))
  }), _createSlots({
    "selected-item": _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.selectLabel), 1)
    ]),
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", null, [
        _createVNode(_component_q_item_section, { class: "app-select-hierarchical__label" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_label, { class: "app-select-hierarchical__type-title" }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([
                'app-select-hierarchical__label-type',
                { 'app-select-hierarchical__label-disabled': opt.disable },
              ])
                }, _toDisplayString(opt.label), 3)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024),
        _createVNode(AppSeparator, { class: "app-select-hierarchical__separator" }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opt.items, (subItem) => {
          return (_openBlock(), _createBlock(_component_q_item, {
            key: subItem.value,
            clickable: "",
            class: _normalizeClass([
            {
              'app-select-section__item-selected': subItem.value === model.value,
            },
          ]),
            disable: subItem.disable,
            "model-value": isItemSelected(subItem),
            onClick: ($event: any) => (selectItem(subItem))
          }, {
            default: _withCtx(() => [
              (subItem.disableTooltip)
                ? (_openBlock(), _createBlock(_unref(BaseTooltip), { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(subItem.disableTooltip), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              _createVNode(_component_q_item_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { class: "app-select-hierarchical__item-container" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(subItem.label), 1),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(subItem.valueDescription), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["class", "disable", "model-value", "onClick"]))
        }), 128))
      ])
    ]),
    _: 2
  }, [
    (showPlaceholder.value)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.placeholder), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["modelValue", "options", "dropdown-icon", "input-debounce", "class", "error"]))
}
}

})