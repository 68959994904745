import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { AppInfiniteScrollRef } from '@/shared/types/components';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';;
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppInfiniteScroll',
  setup(__props, { expose: __expose }) {

const appInfiniteScroll = ref<QInfiniteScroll>();

function setIndex(index: number) {
  appInfiniteScroll.value?.setIndex(index);
}

function poll() {
  appInfiniteScroll.value?.poll();
}

function stop() {
  appInfiniteScroll.value?.stop();
}

function resume() {
  appInfiniteScroll.value?.resume();
}

function trigger() {
  appInfiniteScroll.value?.trigger();
}

__expose<AppInfiniteScrollRef>({
  setIndex,
  poll,
  stop,
  resume,
  trigger,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(QInfiniteScroll), _mergeProps({
    ref_key: "appInfiniteScroll",
    ref: appInfiniteScroll,
    class: "app-infinite-scroll"
  }, _ctx.$attrs), {
    loading: _withCtx(() => [
      _renderSlot(_ctx.$slots, "loading")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}
}

})