import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "app-field__content",
  "data-testid": "app-field-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_field = _resolveComponent("q-field")!

  return (_openBlock(), _createBlock(_component_q_field, _mergeProps(_ctx.$attrs, {
    class: "app-field",
    "data-testid": "app-field",
    error: _ctx.hasError,
    "no-error-icon": "",
    borderless: ""
  }), {
    error: _withCtx(() => [
      _renderSlot(_ctx.$slots, "error", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 16, ["error"]))
}