import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-wizard-footer" }
const _hoisted_2 = { class: "app-wizard-footer__flow-buttons" }

import { AppButton, BaseTooltip } from '@/plugins/commons';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  isLastStep: boolean;
  isFirstStep: boolean;
}

interface Emits {
  (e: 'continue'): void;
  (e: 'previous'): void;
  (e: 'cancel'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppWizardFooter',
  props: {
    isLastStep: { type: Boolean },
    isFirstStep: { type: Boolean }
  },
  emits: ["continue", "previous", "cancel"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;
const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(AppButton), {
      type: "tertiary",
      size: "L",
      label: _unref(t)('labels.common.cancel'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel')))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseTooltip), null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('games.challengeWizard.cancel')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["label"]),
    _createElementVNode("div", _hoisted_2, [
      (!props.isFirstStep)
        ? (_openBlock(), _createBlock(_unref(AppButton), {
            key: 0,
            type: "secondary",
            size: "L",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('previous')))
          }, {
            default: _withCtx(() => [
              _createVNode(IconArrowDown, {
                width: "16",
                height: "16",
                class: "app-wizard-footer__arrow"
              }),
              _createTextVNode(" " + _toDisplayString(_unref(t)('labels.common.previous')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!props.isLastStep)
        ? (_openBlock(), _createBlock(_unref(AppButton), {
            key: 1,
            type: "primary",
            size: "L",
            label: _unref(t)('labels.common.continue'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('continue')))
          }, {
            default: _withCtx(() => [
              _createVNode(IconArrowDown, {
                width: "16",
                height: "16",
                class: "app-wizard-footer__arrow--front"
              })
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})