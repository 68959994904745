import { defineStore } from 'pinia';
import dashboardService from '@/shared/services/dashboardService/dashboardService';
import challengesService from '@/shared/services/challengesService/challengesService';
import { ChallengeParticipant } from '@/shared/types/user';
import { getDisplayedUsername } from '@/shared/helpers/isFormerMember/isFormerMember';
import { Sites } from '@/shared/types/settings';
import {
  DashboardMetricChart,
  ChallengeKpisResponse,
  DashboardMetricChartDateInterval,
  LineChartsRequest,
  BarChartRequest,
} from '@/shared/types/dashboard';
import { IChallenge } from '@/shared/types/wizard';

export default defineStore({
  id: 'dashboard',

  state: () => ({
    challenges: [] as IChallenge[],
    challengeRequestParams: [] as IChallenge[],
    challengesTotalCount: 0,
    metricLineChart: {} as DashboardMetricChart,
    metricBarChart: {} as DashboardMetricChart,
  }),

  getters: {
    isMaxGranularity(state): boolean {
      return (
        state.metricLineChart.chartDateInterval ===
        DashboardMetricChartDateInterval.HOUR
      );
    },
  },

  actions: {
    async loadChallenges(
      initialDate: number,
      endDate: number,
      page: number,
      size: number,
    ): Promise<void> {
      const response = await dashboardService.getChartChallenges(
        initialDate,
        endDate,
        page,
        size,
      );
      this.challengeRequestParams = response.results.challenges;
      this.challengesTotalCount = response.results.totalOfChallenges;
    },

    async loadChallengeDetails(challengeReference: string): Promise<void> {
      const challengeIndex = this.challenges.findIndex(
        (challenge) => challenge.reference === challengeReference,
      );

      const details = await challengesService.getChallengeDetails(
        challengeReference,
      );

      this.challenges[challengeIndex] = {
        ...this.challenges[challengeIndex],
        ...details,
      };
    },

    async loadChallengeKpis(
      challengeReference: string,
    ): Promise<ChallengeKpisResponse> {
      return dashboardService.getChallengeKpis(challengeReference);
    },

    async loadChallengeParticipants(
      gameReference: string,
      sitesReferences?: string[],
    ): Promise<ChallengeParticipant[]> {
      return (
        await dashboardService.getChallengeParticipants(
          gameReference,
          sitesReferences,
        )
      ).results.map((participant) => ({
        ...participant,
        name: getDisplayedUsername(participant.name),
      }));
    },

    async loadChallengeSites(gameReference: string): Promise<Sites[]> {
      return (await dashboardService.getChallengeSites(gameReference)).results;
    },

    async loadLineChart(
      gameReference: string,
      params: LineChartsRequest,
    ): Promise<void> {
      this.metricLineChart = await dashboardService.getLineChart(
        gameReference,
        params,
      );
    },

    async loadBarChart(
      gameReference: string,
      params: BarChartRequest,
    ): Promise<void> {
      this.metricBarChart = await dashboardService.getBarChart(
        gameReference,
        params,
      );
    },
  },
});
