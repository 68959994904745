import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "app-loading-row__content",
  "data-testid": "app-loading-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfSkeletons, (element, index) => {
      return (_openBlock(), _createBlock(_component_q_skeleton, {
        key: `${index + element}`,
        type: _ctx.skeletons[index].type,
        style: _normalizeStyle(_ctx.skeletons[index].style)
      }, null, 8, ["type", "style"]))
    }), 128))
  ]))
}