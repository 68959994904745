import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bullet-slider" }

import { BulletSlider } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBulletSlider',
  props: {
    totalBullets: {},
    highlightedIndex: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalBullets, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['bullet', { 'bullet-highlighted': index === _ctx.highlightedIndex }])
      }, null, 2))
    }), 128))
  ]))
}
}

})