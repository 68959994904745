import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "validation-item__state" }
const _hoisted_2 = { class: "validation-item__text" }

import IconSuccess from '@/assets/icons/icon-success.vue';

interface Props {
  state: boolean;
  text: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppValidationItem',
  props: {
    state: { type: Boolean },
    text: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['validation-item', { 'validation-item--checked': _ctx.state }])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(IconSuccess)
    ]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 2))
}
}

})