<template>
  <q-input
    ref="input"
    v-model="model"
    v-bind="bind"
    outlined
    data-testid="app-input"
    bottom-slots
    no-error-icon
    :dark="false"
    :class="appInputClass"
    :style="appInputStyle"
    :error="isError"
    :input-style="inputStyle"
    @blur="trimSpaces"
  >
    <template v-if="!hideBottomSpace" #error>
      <slot name="error">
        <AppErrorMessage :error="error" />
      </slot>
    </template>
    <template v-for="(_, slot) of $slots" #[slot]>
      <slot :name="slot" />
    </template>
    <span v-if="isTextarea" class="app-input__word-counter">
      {{ wordsLeft }}
    </span>
  </q-input>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from 'vue';
import { QInput } from 'quasar';
import { isNumeric, convertStringToNumber } from '@/plugins/commons';
import { useI18n } from 'vue-i18n';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';

export default defineComponent({
  name: 'AppInput',

  components: { AppErrorMessage },

  props: {
    modelValue: {
      type: [Object, String, Number],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    formatNumbers: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    clearIcon: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
    autogrow: {
      type: Boolean,
      default: false,
    },
    disable: Boolean,
    debounce: {
      type: Number,
      default: undefined,
    },
    hideBottomSpace: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const { n } = useI18n();

    return { n };
  },

  computed: {
    model: {
      get(): string {
        const newValue =
          this.modelValue !== undefined && this.modelValue !== null
            ? this.modelValue.toString()
            : '';

        if (isNumeric(newValue) && this.formatNumbers) {
          if (convertStringToNumber(newValue))
            return this.n(convertStringToNumber(newValue));
          return '';
        }
        return newValue;
      },

      set(newValue?: string | number) {
        let updatedValue =
          newValue === undefined || newValue === null ? '' : newValue;

        if (
          newValue &&
          isNumeric(convertStringToNumber(newValue.toString()).toString()) &&
          this.formatNumbers
        ) {
          updatedValue =
            typeof newValue === 'number'
              ? newValue
              : convertStringToNumber(newValue.toString());
        }

        if (this.type === 'number' && Number.isNaN(Number(updatedValue))) {
          updatedValue = '';
        }

        this.$emit('update:modelValue', updatedValue);
        this.$forceUpdate();
      },
    },

    bind() {
      return {
        placeholder: this.placeholder,
        maxlength: this.maxlength,
        type: this.type === 'number' ? 'text' : this.type,
        dense: this.dense,
        clearable: this.clearable,
        clearIcon: this.clearIcon,
        rules: this.rules,
        disable: this.disable,
        autogrow: this.autogrow,
        debounce: this.debounce,
        hideBottomSpace: this.hideBottomSpace,
      };
    },

    appInputStyle(): StyleValue {
      return {
        width: this.width,
      };
    },

    appInputClass() {
      return ['app-input', { 'app-input--focused': this.isFocused }];
    },

    isTextarea(): boolean {
      return this.type === 'textarea';
    },

    wordsLeft(): number | undefined {
      if (this.maxlength) {
        return typeof this.model === 'string'
          ? this.maxlength - this.model.length
          : this.maxlength;
      }

      return undefined;
    },

    isError(): boolean {
      return (this.error?.length || 0) > 0;
    },
  },

  methods: {
    focusInput() {
      (this.$refs.input as QInput).focus();
    },

    inputBlur() {
      (this.$refs.input as QInput).blur();
    },

    selectContent() {
      (this.$refs.input as QInput).select();
    },

    trimSpaces() {
      this.model = this.model.trim();
    },
  },
});
</script>

<style scoped lang="scss">
.app-input :deep(.q-field__control) {
  padding: 0;
  border-radius: 4px;
  color: $gray-800;
  background-color: $white;
  border: 1px solid $gray-400;
  height: 48px;
}

.app-input :deep(.q-field__control):hover {
  border: 1px solid $primary-color;
}

.app-input :deep(.q-field__control-container) {
  height: 100%;
}

.app-input.q-field--dense :deep(.q-field__control) {
  height: 32px;
}

.app-input.q-field--dense :deep(input.q-field__native) {
  padding: 7px;
}

.app-input.q-field--dense :deep(.q-field__append),
.app-input.q-field--dense :deep(.q-field__prepend) {
  padding: 0 5px;
}

.app-input :deep(.q-field--error .q-field__control) {
  border: 2px solid $red-500;
}

.app-input :deep(.q-field__control:after),
.app-input :deep(.q-field__control:before) {
  display: none;
}

.app-input.q-field--focused :deep(.q-field__control),
.app-input--focused :deep(.q-field__control) {
  border-color: $primary-color;
}

.app-input :deep(input.q-field__native) {
  padding: 14px 16px;
}

.app-input :deep(input.q-field__native:-webkit-autofill) {
  border-radius: 3px;
  margin: 0;
}

.app-input :deep(input::placeholder),
.app-input :deep(textarea::placeholder) {
  color: $gray-500;
}

.app-input.q-textarea.q-field--dense :deep(.q-field__control),
.app-input.q-textarea :deep(.q-field__control) {
  height: auto;
  padding: 0;
  min-height: auto;
}

.app-input.q-textarea :deep(.q-field__control-container) {
  padding: 0;
}

.app-input.q-textarea.q-field--dense :deep(.q-field__native) {
  padding: 7px;
}

.app-input.q-textarea:not(.q-field--dense) :deep(.q-field__native) {
  padding: 14px 32px 14px 16px;
  min-height: auto;
}

.app-input :deep(.q-field__marginal) {
  height: 100%;
}

.app-input :deep(.q-field__control.text-negative) {
  border: 1px solid $red-500;
}

.app-input.q-field--error :deep(.q-field__bottom) {
  color: $red-500;
}

.app-input :deep(.q-field__append),
.app-input :deep(.q-field__prepend) {
  padding: 0 16px;
  background: $white;
  z-index: 0;
}

.app-input :deep(.q-field__append) {
  margin-left: -10px;
  border-radius: 0 3px 3px 0;
}

.app-input :deep(.q-field__prepend) {
  margin-right: -10px;
  border-radius: 3px 0 0 3px;
}

.app-input :deep(.q-field__append span.q-icon),
.app-input :deep(.q-field__prepend span.q-icon) {
  color: $gray-700;
}

.app-input :deep(.q-field__prepend) {
  padding: 0 16px;
  margin-right: -15px;
  background: $white;
  z-index: 0;
  border-radius: 3px 0 0 3px;
}

.app-input :deep(.q-field__prepend:empty) {
  display: none;
}

.app-input :deep(.q-field__inner.col) {
  flex: unset;
  width: 100%;
}

.app-input :deep(textarea ~ .app-input__word-counter) {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: $gray-500;
}

.app-input :deep(textarea:focus ~ .app-input__word-counter) {
  color: $primary-color;
}

.app-input :deep(input::-webkit-outer-spin-button),
.app-input :deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

.app-input :deep(input[type='number']) {
  appearance: textfield;
  -moz-appearance: textfield;
}

.app-input.q-field--disabled :deep(.q-field__control) {
  background-color: $gray-50;
  border-color: $gray-50;
}

.app-input.q-field--disabled :deep(.q-field__append),
.app-input.q-field--disabled :deep(.q-field__prepend) {
  background: unset;
}
</style>
