<template>
  <div :class="['app-countdown', { 'app-countdown--badge': badge }]">
    <AppTooltip
      v-if="tooltip"
      anchor="top middle"
      self="bottom middle"
      :offset="[10, 10]"
      max-width="200px"
    >
      <div class="app-countdown__tooltip">
        <span :class="['app-countdown__label']"> {{ leftDays }}</span>
        <span v-if="tooltipText !== ''">{{ tooltipText }}</span>
      </div>
    </AppTooltip>
    <p
      class="app-countdown__progress__text"
      data-testid="app-countdown-progress-text"
    >
      {{ badge ? badgeLabel : leftDays }}
    </p>
    <div
      data-testid="app-countdown-progress-container"
      :class="['app-countdown__progress__container', getChargeColor]"
      :style="{
        width: `${percentualChallange}%`,
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ChallengeStatus } from '@/shared/types/challenges';
import { differenceInSeconds, intervalToDuration } from 'date-fns';
import { useI18n } from 'vue-i18n';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

export default defineComponent({
  components: { AppTooltip },
  props: {
    endDate: { type: Number, default: 0 },
    initialDate: { type: Number, default: 0 },
    status: {
      type: String as PropType<ChallengeStatus>,
      default: ChallengeStatus.ON_GOING,
    },
    badge: { type: Boolean, default: false },
    badgeLabel: { type: String, default: '' },
    tooltip: { type: Boolean, default: false },
    tooltipText: { type: String, default: '' },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  computed: {
    percentualChallange(): number {
      const diffInitalDayToFinalSeconds = this.diffInitalDayToFinalSeconds();
      const diffTodayToFinalSeconds = this.diffTodayToFinalSeconds();

      const passedSeconds =
        diffInitalDayToFinalSeconds - diffTodayToFinalSeconds;

      const percentualDif = (passedSeconds / diffInitalDayToFinalSeconds) * 100;

      const percentual = percentualDif > 100 ? 100 : percentualDif;

      if (this.status === ChallengeStatus.CALCULATING_RESULTS) {
        return 100;
      }

      return diffTodayToFinalSeconds > diffInitalDayToFinalSeconds
        ? 100
        : percentual;
    },

    leftDays(): string {
      const challengeDate = new Date(Date.now());
      const endDate = new Date(this.endDate);

      const interval = intervalToDuration({
        start: challengeDate,
        end: endDate,
      });

      if (this.status === ChallengeStatus.CALCULATING_RESULTS) {
        return `${this.t('games.sidebar.calculating')}`;
      }

      if (interval.years) {
        return this.t('common.left.years', interval.years);
      }

      if (interval.months) {
        return this.t('common.left.months', interval.months);
      }

      if (interval.days) {
        return this.t('common.left.days', interval.days);
      }

      if (interval.hours) {
        return this.t('common.left.hours', interval.hours);
      }

      if (interval.minutes) {
        return this.t('common.left.minutes', interval.minutes);
      }

      return `${this.t('games.sidebar.calculating')}`;
    },

    getChargeColor(): string {
      const missingPercentage = 100 - this.percentualChallange;

      if (this.status === ChallengeStatus.CALCULATING_RESULTS) {
        return 'app-countdown__progress__color__calculating';
      }

      return missingPercentage > 10
        ? 'app-countdown__progress__color__green'
        : 'app-countdown__progress__color__red';
    },
  },

  methods: {
    diffInitalDayToFinalSeconds(): number {
      const initialDay = new Date(this.initialDate);
      const endDay = new Date(this.endDate);

      return differenceInSeconds(endDay, initialDay);
    },

    diffTodayToFinalSeconds(): number {
      const today = new Date(Date.now());
      const endDay = new Date(this.endDate);

      return differenceInSeconds(endDay, today);
    },
  },
});
</script>

<style scoped lang="scss">
.app-countdown {
  height: 22px;
  position: relative;
  overflow: hidden;
  text-align: right;
  border-radius: 4px;
  background-color: $gray-50;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app-countdown__progress__container {
  opacity: 0.4;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.app-countdown__progress__color__green {
  background-color: $green-500;
}

.app-countdown__progress__color__red {
  background-color: $red-300;
}

.app-countdown__progress__color__calculating {
  background: repeating-linear-gradient(
    120deg,
    $green-100,
    $green-100 10px,
    $green-500 10px,
    $green-500 20px
  );
}

.app-countdown__progress__text {
  position: relative;
  color: $gray-700;
  font-size: 11px;
  font-weight: 400;
  margin: 0 8px;
  z-index: 1;
}

.app-countdown--badge {
  width: 90px;
  justify-content: center;
  border-radius: 4px;
}

.app-countdown--badge .app-countdown__progress__text {
  color: $gray-800;
  font-size: 12px;
  margin: 0;
}

.app-countdown__label {
  font-weight: 700;
}

.app-countdown__label--badge {
  font-weight: 400;
}

.app-countdown__tooltip {
  display: flex;
  flex-direction: column;
}
</style>
