import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "app-date-picker",
  "data-testid": "app-date-picker"
}
const _hoisted_2 = {
  class: "row items-center justify-end",
  "data-testid": "app-date-picker-input-popup-items"
}
const _hoisted_3 = {
  key: 0,
  class: "app-date-picker__prepend-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppDate = _resolveComponent("AppDate")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInput, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
      "data-testid": "app-date-picker-input",
      width: _ctx.width,
      placeholder: _ctx.placeholder || _ctx.$t('common.dates.placeholderShort'),
      error: _ctx.error,
      disable: _ctx.disabled,
      readonly: "",
      onClick: _ctx.onFocusedInput
    }, {
      prepend: _withCtx(() => [
        _createVNode(_component_AppIcon, {
          name: _ctx.outlinedEvent,
          class: "app-date-picker__prepend-icon cursor-pointer",
          "data-testid": "app-date-picker-input-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              modelValue: _ctx.showDatepicker,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDatepicker) = $event)),
              "data-testid": "app-date-picker-input-popup",
              "transition-show": "jump-down",
              "transition-hide": "jump-up",
              offset: [20, 20]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppDate, {
                  modelValue: _ctx.model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
                  mask: _ctx.mask,
                  "data-testid": "app-date-picker-input-popup-date"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _withDirectives(_createVNode(_component_AppButton, {
                        class: "app-date-picker__close",
                        "data-testid": "app-date-picker-input-popup-close-button",
                        type: "tertiary",
                        label: _ctx.$t('common.close')
                      }, null, 8, ["label"]), [
                        [_directive_close_popup]
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "mask"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["name"]),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "width", "placeholder", "error", "disable", "onClick"])
  ]))
}