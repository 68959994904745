import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "16",
  viewBox: "0 0 20 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-product-tour',
  props: {
    color: { default: GRAY_700 },
    secondaryColor: {},
    tertiaryColor: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9.99993 3.50002C9.46949 3.5 8.96078 3.28926 8.58572 2.91417C8.52118 2.84962 8.24035 2.64338 7.6478 2.43547C7.09254 2.24065 6.39409 2.09094 5.6359 2.03919C4.28418 1.94693 2.97891 2.17614 2 2.75089V13.6193C5.03651 12.6089 8.15488 13.1523 10.0146 13.9498C11.8355 13.1962 14.9593 12.6358 18 13.6319V2.75089C17.0211 2.17617 15.7159 1.94699 14.3641 2.03927C13.6059 2.09103 12.9075 2.24075 12.3522 2.43557C11.7596 2.64348 11.4787 2.84972 11.4142 2.91427C11.0391 3.28934 10.5304 3.50004 9.99993 3.50002ZM10.0002 1.49979C11.4462 0.0542868 16.6073 -0.926942 19.6648 1.46932C19.8852 1.64208 20 1.91314 20 2.1932V14.8256C20 15.68 18.9487 16.1894 18.1695 15.8391C15.3565 14.5742 12.1842 15.1561 10.59 15.8799C10.2192 16.0482 9.78233 16.0453 9.41383 15.8721C7.79189 15.1095 4.63908 14.5495 1.82703 15.8331C1.04982 16.1878 0 15.68 0 14.8256V2.19321C0 1.91314 0.114809 1.64208 0.335241 1.46932C3.39287 -0.927065 8.55451 0.0539519 10.0002 1.49979Z",
      fill: props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9 14.9998L9 2.99981H11L11 14.9998H9Z",
      fill: props.color
    }, null, 8, _hoisted_3)
  ]))
}
}

})