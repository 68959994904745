import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-wizard-layout" }
const _hoisted_2 = { class: "app-wizard-layout__container" }
const _hoisted_3 = { class: "app-wizard-layout__content-container" }
const _hoisted_4 = { class: "app-wizard-layout__header" }
const _hoisted_5 = { class: "app-wizard-layout__body" }
const _hoisted_6 = { class: "app-wizard-layout__footer" }

interface Props {
  isLastStep: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppWizardLayout',
  props: {
    isLastStep: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "body")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
        props.isLastStep
          ? 'app-wizard-layout__sidebar--summary'
          : 'app-wizard-layout__sidebar',
      ])
    }, [
      _renderSlot(_ctx.$slots, "sidebar")
    ], 2)
  ]))
}
}

})