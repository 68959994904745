import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-question__container"
}
const _hoisted_2 = { class: "app-question__order" }
const _hoisted_3 = { class: "app-question__default-custom" }
const _hoisted_4 = { class: "app-question__text" }
const _hoisted_5 = {
  key: 1,
  class: "app-question__container app-question__container--editable"
}
const _hoisted_6 = { class: "app-question__order" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_IconDelete = _resolveComponent("IconDelete")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (!_ctx.editable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.numberQuestion), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.question), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.numberQuestion), 1),
        _createVNode(_component_AppInput, {
          modelValue: _ctx.customQuestion,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customQuestion) = $event)),
          width: _ctx.widthInputQuestion,
          error: _ctx.error,
          maxlength: 90,
          type: "textarea",
          rows: 1,
          autogrow: true
        }, null, 8, ["modelValue", "width", "error"]),
        (_ctx.showDeleteButton)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              class: "app-question__remove-icon",
              type: "tertiary",
              size: "S",
              "is-button-icon": "",
              onClick: _ctx.deleteQuestion
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconDelete, {
                  width: "16px",
                  height: "16px"
                })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]))
}