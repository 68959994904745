import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import { SidebarAction, SidebarActionList } from '@/shared/types/components';
import { AppButton } from '@/plugins/commons';
import { GRAY_600 } from '@/shared/constants/colors';
import IconTripleDots from '@/assets/icons/icon-triple-dots.vue';
import AppSidebarMenuItem from '../AppSidebarMenuItem/AppSidebarMenuItem.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';
import AppButtonDropdown from '../AppButtonDropdown/AppButtonDropdown.vue';

interface Props {
  list: SidebarActionList;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarActions',
  props: {
    list: {}
  },
  setup(__props: any) {

const props = __props;

const filteredList = computed<SidebarAction[]>(() => {
  return (
    props.list.dropdownList?.filter((item: SidebarAction) => !item.isHidden) ??
    []
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.mainActionList, (item) => {
      return (_openBlock(), _createBlock(_unref(AppButton), {
        key: item.label,
        disable: item.disabled,
        class: "app-sidebar-action__button",
        type: "tertiary",
        size: "S",
        "is-button-icon": "",
        onClick: item.action
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
            width: "16",
            height: "16",
            color: _unref(GRAY_600)
          }, null, 8, ["color"])),
          _createVNode(AppTooltip, {
            anchor: "top middle",
            self: "bottom middle",
            offset: [5, 5],
            "max-width": "220px"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.tooltip), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["disable", "onClick"]))
    }), 128)),
    (filteredList.value.length)
      ? (_openBlock(), _createBlock(AppButtonDropdown, {
          key: 0,
          size: "S",
          type: "tertiary",
          "is-button-icon": ""
        }, {
          default: _withCtx(() => [
            _createVNode(IconTripleDots, {
              width: "16",
              height: "16"
            })
          ]),
          menu: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredList.value, (item) => {
              return (_openBlock(), _createBlock(AppSidebarMenuItem, {
                key: item.label,
                label: item.label,
                description: item.description,
                disabled: item.disabled,
                class: "app-sidebar-action__button",
                onClick: item.action
              }, {
                icon: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    width: "16",
                    height: "16",
                    color: _unref(GRAY_600)
                  }, null, 8, ["color"]))
                ]),
                default: _withCtx(() => [
                  (item.disabled)
                    ? (_openBlock(), _createBlock(AppTooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [5, 5],
                        "max-width": "220px"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.tooltip), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["label", "description", "disabled", "onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})