import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-list-item__container" }
const _hoisted_2 = {
  class: "app-list-item__title",
  "data-testid": "app-list-item-title"
}
const _hoisted_3 = {
  class: "app-list-item__subtitle",
  "data-testid": "app-list-item-subtitle"
}
const _hoisted_4 = {
  key: 0,
  class: "app-list-item__description",
  "data-testid": "app-list-item-description"
}
const _hoisted_5 = {
  key: 1,
  class: "app-list-item__count",
  "data-testid": "app-list-item-count"
}
const _hoisted_6 = {
  class: "app-list-item__actions",
  "data-testid": "app-list-item-actions"
}

import IconEdit from '@/assets/icons/icon-edit.vue';
import IconDelete from '@/assets/icons/icon-delete.vue';
import { AppButton } from '@/plugins/commons';

interface Props {
  editTooltip?: string;
  deleteTooltip?: string;
  isDeleteDisabled?: boolean;
  isEditDisabled?: boolean;
  alignedTop?: boolean;
}
interface Emits {
  (e: 'edit'): void;
  (e: 'delete'): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppListItem',
  props: {
    editTooltip: {},
    deleteTooltip: {},
    isDeleteDisabled: { type: Boolean },
    isEditDisabled: { type: Boolean },
    alignedTop: { type: Boolean }
  },
  emits: ["edit", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

function handleEditClick(): void {
  if (!props.isEditDisabled) {
    emit('edit');
  }
}
function handleDeleteClick(): void {
  if (!props.isDeleteDisabled) {
    emit('delete');
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-list-item', { 'app-list-item--top-aligned': _ctx.alignedTop }]),
    "data-testid": "app-list-item"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "leading"),
      _createElementVNode("span", null, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title")
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "subtitle")
          ])
        ])
      ])
    ]),
    (_ctx.$slots.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "description")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.numberOfItens)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _renderSlot(_ctx.$slots, "numberOfItens")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "actions"),
      (!_ctx.$slots.actions)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_unref(AppButton), {
              type: "tertiary",
              size: "S",
              "is-button-icon": "",
              disabled: _ctx.isEditDisabled,
              "tooltip-text": _ctx.editTooltip,
              "data-testid": "app-list-item-actions-edit-button",
              onClick: handleEditClick
            }, {
              default: _withCtx(() => [
                _createVNode(IconEdit, {
                  width: "16px",
                  height: "16px"
                })
              ]),
              _: 1
            }, 8, ["disabled", "tooltip-text"]),
            _createVNode(_unref(AppButton), {
              type: "tertiary",
              size: "S",
              "is-button-icon": "",
              disabled: _ctx.isDeleteDisabled,
              "tooltip-text": _ctx.deleteTooltip,
              "data-testid": "app-list-item-actions-delete",
              onClick: handleDeleteClick
            }, {
              default: _withCtx(() => [
                _createVNode(IconDelete, {
                  width: "16px",
                  height: "16px"
                })
              ]),
              _: 1
            }, 8, ["disabled", "tooltip-text"])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})