import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

import { AppButton, BaseButtonSize, BaseButtonType } from '@/plugins/commons';
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import AppDropdownMenu from '../AppDropdownMenu/AppDropdownMenu.vue';

interface Props {
  type?: BaseButtonType;
  size?: BaseButtonSize;
  buttonIcon?: boolean;
  isButtonIcon?: boolean;
  tooltipText?: string;
  disable?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppButtonDropdown',
  props: {
    type: { default: 'primary' },
    size: { default: 'M' },
    buttonIcon: { type: Boolean },
    isButtonIcon: { type: Boolean },
    tooltipText: { default: '' },
    disable: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(AppButton), {
      type: _ctx.type,
      size: _ctx.size,
      "button-icon": _ctx.buttonIcon,
      "is-button-icon": _ctx.isButtonIcon,
      disable: _ctx.disable
    }, {
      default: _withCtx(() => [
        (_ctx.$slots.default)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : (_openBlock(), _createBlock(IconArrowDown, { key: 1 }))
      ]),
      _: 3
    }, 8, ["type", "size", "button-icon", "is-button-icon", "disable"]),
    _createVNode(AppDropdownMenu, {
      offset: [10, 10],
      class: "app-button-dropdown__menu"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "menu")
      ]),
      _: 3
    }),
    (props.tooltipText)
      ? (_openBlock(), _createBlock(_unref(BaseTooltip), { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.tooltipText), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})