import { ApiResponse } from '@/shared/types/generic';
import {
  PermissionCode,
  RoleLevelSimplified,
  ProfileRoleLevelResponse,
  RoleLevelsSimplified,
  RoleLevelsCount,
  OrgRoleLevelsResponse,
  RoleLevelTypeStaticReference,
} from '@/shared/types/roles';
import {
  Permission,
  PermissionAction,
  PermissionType,
  Role,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';

export const examplePermissionType: PermissionType = {
  name: 'Challenges',
  description: 'Permissions for challenges',
  staticReference: 'Challenges',
  active: true,
  permissions: [
    {
      name: 'Read',
      description: 'Read',
      code: 'ChallengesRead',
      permissionActionStaticReference: PermissionAction.READ,
      active: true,
    },
    {
      name: 'Edit',
      description: 'Edit',
      code: 'ChallengesEdit',
      permissionActionStaticReference: PermissionAction.EDIT,
      active: true,
    },
    {
      name: 'Duplicate',
      description: 'Duplicate',
      code: 'ChallengesDuplicate',
      permissionActionStaticReference: PermissionAction.DUPLICATE,
      active: true,
    },
    {
      name: 'Delete',
      description: 'Delete',
      code: 'ChallengesDelete',
      permissionActionStaticReference: PermissionAction.CREATE,
      active: false,
    },
  ] as Permission[],
} as PermissionType;

export const mockPermissionType = (): PermissionType => {
  return examplePermissionType;
};

export const exampleRoleLevel: Role = {
  name: 'Manager',
  description:
    'Role for manager with a very very very very long description about what it does and it occupies multiples lines on this',
  reference: 'Manager',
  active: true,
  permissionTypes: [examplePermissionType] as PermissionType[],
} as Role;

export const mockRoleLevelSimplified = (): RoleLevelSimplified => {
  return {
    name: 'Super Manager',
    description: 'Description for super manager',
    reference: 'superManagerReference',
  };
};

export const mockRoleLevelsSimplified = (): RoleLevelsSimplified => {
  return {
    defaultRoleLevels: [
      {
        name: 'role.manager',
        description: '',
        reference: 'managerReference',
      },
      {
        name: 'role.superManager',
        description: '',
        reference: 'superManagerReference',
      },
    ],
    customRoleLevels: [
      mockRoleLevelSimplified(),
      {
        name: 'Custom Role Level 2',
        description:
          'Role for manager with a very very very very long description about what it does and it occupies multiples lines on this',
        reference: 'custom2Reference',
      },
    ],
  };
};

export const mockPermissionsList = (): PermissionCode[] => {
  return [
    PermissionCode.DASHBOARD_READ,
    PermissionCode.CHALLENGE_CREATE,
    PermissionCode.CHALLENGE_EDIT,
    PermissionCode.CHALLENGE_DUPLICATE,
    PermissionCode.CHALLENGE_DELETE,
    PermissionCode.CHALLENGE_READ,
    PermissionCode.CHALLENGE_FINISH_NOW,
    PermissionCode.CHALLENGE_CANCEL,
    PermissionCode.LEADERBOARD_READ,
    PermissionCode.STORE_CREATE_PRIZE,
    PermissionCode.STORE_CREATE_CATEGORY,
    PermissionCode.STORE_READ_ITEMS,
    PermissionCode.STORE_EDIT_ITEMS,
    PermissionCode.STORE_DELETE_PRIZE,
    PermissionCode.STORE_DELETE_CATEGORY,
    PermissionCode.PURCHASE_ACTIVITY_READ,
    PermissionCode.PURCHASE_ACTIVITY_REFUND,
    PermissionCode.USERS_CREATE,
    PermissionCode.USERS_READ,
    PermissionCode.USERS_EDIT,
    PermissionCode.USERS_DELETE,
    PermissionCode.TEAMS_CREATE,
    PermissionCode.TEAMS_EDIT,
    PermissionCode.TEAMS_ADD_USERS,
    PermissionCode.TEAMS_REMOVE,
    PermissionCode.TEAMS_READ,
    PermissionCode.METRICS_CREATE,
    PermissionCode.METRICS_READ,
    PermissionCode.METRICS_EDIT,
    PermissionCode.METRICS_DELETE,
    PermissionCode.METRICS_ADD_METRICS_TO_CATEGORY,
    PermissionCode.CATEGORY_CREATE,
    PermissionCode.CATEGORY_READ,
    PermissionCode.CATEGORY_EDIT,
    PermissionCode.CATEGORY_DELETE,
    PermissionCode.DATA_IMPORT_CREATE,
    PermissionCode.DATA_IMPORT_DELETE,
    PermissionCode.DATA_IMPORT_EXPORT,
    PermissionCode.DATA_IMPORT_READ,
    PermissionCode.PUBLIC_DISPLAYS_READ,
    PermissionCode.SURVEYS_CREATE,
    PermissionCode.SURVEYS_EDIT,
    PermissionCode.SURVEYS_DELETE,
    PermissionCode.SURVEYS_CANCEL,
    PermissionCode.SURVEYS_READ,
    PermissionCode.SITES_CREATE,
    PermissionCode.SITES_EDIT,
    PermissionCode.SITES_DELETE,
    PermissionCode.SITES_READ,
    PermissionCode.ROLES_CREATE_CUSTOM,
    PermissionCode.ROLES_EDIT_PERMISSONS,
    PermissionCode.ROLES_ADD_USERS_TO_ROLES,
    PermissionCode.ROLES_READ,
    PermissionCode.ROLES_DELETE,
    PermissionCode.INTEGRATION_READ,
    PermissionCode.INTEGRATION_CREATE,
    PermissionCode.INTEGRATION_DELETE,
    PermissionCode.INTEGRATION_EDIT,
  ];
};

export const mockPermissionsMap = (): Record<PermissionCode, boolean> => {
  return {
    [PermissionCode.DASHBOARD_READ]: true,
    [PermissionCode.CHALLENGE_CREATE]: true,
    [PermissionCode.CHALLENGE_EDIT]: true,
    [PermissionCode.CHALLENGE_DUPLICATE]: true,
    [PermissionCode.CHALLENGE_DELETE]: true,
    [PermissionCode.CHALLENGE_READ]: true,
    [PermissionCode.CHALLENGE_FINISH_NOW]: true,
    [PermissionCode.CHALLENGE_CANCEL]: true,
    [PermissionCode.LEADERBOARD_READ]: true,
    [PermissionCode.STORE_CREATE_PRIZE]: true,
    [PermissionCode.STORE_CREATE_CATEGORY]: true,
    [PermissionCode.STORE_READ_ITEMS]: true,
    [PermissionCode.STORE_EDIT_ITEMS]: true,
    [PermissionCode.STORE_DELETE_PRIZE]: true,
    [PermissionCode.STORE_DELETE_CATEGORY]: true,
    [PermissionCode.PURCHASE_ACTIVITY_READ]: true,
    [PermissionCode.PURCHASE_ACTIVITY_REFUND]: true,
    [PermissionCode.USERS_CREATE]: true,
    [PermissionCode.USERS_READ]: true,
    [PermissionCode.USERS_EDIT]: true,
    [PermissionCode.USERS_DELETE]: true,
    [PermissionCode.TEAMS_CREATE]: true,
    [PermissionCode.TEAMS_EDIT]: true,
    [PermissionCode.TEAMS_ADD_USERS]: true,
    [PermissionCode.TEAMS_REMOVE]: true,
    [PermissionCode.TEAMS_READ]: true,
    [PermissionCode.METRICS_CREATE]: true,
    [PermissionCode.METRICS_READ]: true,
    [PermissionCode.METRICS_EDIT]: true,
    [PermissionCode.METRICS_DELETE]: true,
    [PermissionCode.METRICS_ADD_METRICS_TO_CATEGORY]: true,
    [PermissionCode.CATEGORY_CREATE]: true,
    [PermissionCode.CATEGORY_READ]: true,
    [PermissionCode.CATEGORY_EDIT]: true,
    [PermissionCode.CATEGORY_DELETE]: true,
    [PermissionCode.DATA_IMPORT_CREATE]: true,
    [PermissionCode.DATA_IMPORT_DELETE]: true,
    [PermissionCode.DATA_IMPORT_EXPORT]: true,
    [PermissionCode.DATA_IMPORT_READ]: true,
    [PermissionCode.PUBLIC_DISPLAYS_READ]: true,
    [PermissionCode.SURVEYS_CREATE]: true,
    [PermissionCode.SURVEYS_EDIT]: true,
    [PermissionCode.SURVEYS_DELETE]: true,
    [PermissionCode.SURVEYS_CANCEL]: true,
    [PermissionCode.SURVEYS_READ]: true,
    [PermissionCode.SITES_CREATE]: true,
    [PermissionCode.SITES_EDIT]: true,
    [PermissionCode.SITES_DELETE]: true,
    [PermissionCode.SITES_READ]: true,
    [PermissionCode.ROLES_CREATE_CUSTOM]: true,
    [PermissionCode.ROLES_EDIT_PERMISSONS]: true,
    [PermissionCode.ROLES_ADD_USERS_TO_ROLES]: true,
    [PermissionCode.ROLES_READ]: true,
    [PermissionCode.ROLES_DELETE]: true,
    [PermissionCode.INTEGRATION_READ]: true,
    [PermissionCode.INTEGRATION_CREATE]: true,
    [PermissionCode.INTEGRATION_EDIT]: true,
    [PermissionCode.INTEGRATION_DELETE]: true,
  };
};

export const mockRoleLevels = (): ProfileRoleLevelResponse => {
  return {
    name: 'Manager',
    reference: 'ManagerRef',
    activePermissionsCodes: mockPermissionsList(),
  };
};

export const mockRoleLevelCounters = (): RoleLevelsCount => {
  return {
    numberOfRoleLevels: 5,
  };
};

export const mockOrgRoleLevels = (): ApiResponse<OrgRoleLevelsResponse> => {
  return {
    metadata: {},
    results: {
      rolesPermissions: [
        {
          numberOfManagers: 5,
          permissionsTypeCount: [
            {
              activePermissionCount: 5,
              permissionTypeStaticReference: 'CHALLENGES',
            },
          ],
          roleLevelDescription: 'Manager',
          roleLevelName: 'Manager',
          roleLevelReference: 'Manager',
          roleLevelTypeStaticReference: RoleLevelTypeStaticReference.DEFAULT,
        },
      ],
    },
  };
};
