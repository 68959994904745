import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-challenges',
  props: {
    color: { default: GRAY_700 },
    secondaryColor: {},
    tertiaryColor: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2 15V16H16V15L2 15ZM4.3714e-08 16C9.19962e-08 17.1046 0.895431 18 2 18H16C17.1046 18 18 17.1046 18 16V15C18 13.8954 17.1046 13 16 13L2 13C0.89543 13 -4.82823e-08 13.8954 0 15L4.3714e-08 16Z",
      fill: props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M10 6L10 12H8L8 6H10Z",
      fill: props.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9 6C10.1046 6 11 5.10457 11 4C11 2.89543 10.1046 2 9 2C7.89543 2 7 2.89543 7 4C7 5.10457 7.89543 6 9 6ZM9 8C11.2091 8 13 6.20914 13 4C13 1.79086 11.2091 0 9 0C6.79086 0 5 1.79086 5 4C5 6.20914 6.79086 8 9 8Z",
      fill: props.color
    }, null, 8, _hoisted_4)
  ]))
}
}

})