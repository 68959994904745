import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-results-release__content-message app-results-release__content-message--title" }
const _hoisted_2 = { class: "app-results-release__content-message" }

import { useI18n } from 'vue-i18n';
import { formatDateToString } from '@/shared/helpers/formatDates/formatDates';
import AppMessage from '@/components/app/AppMessage/AppMessage.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppInformation from '@/components/app/AppInformation/AppInformation.vue';

interface Props {
  date: number;
  sidebar?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppResultsRelease',
  props: {
    date: {},
    sidebar: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const profileStore = useProfileStore();

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'app-results-release',
      { 'app-results-release--sidebar': props.sidebar },
    ])
  }, [
    _createVNode(AppMessage, {
      type: "empty",
      class: "app-results-release__content"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)('timezones.resultsRelease.label')), 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(formatDateToString)(
            new Date(props.date),
            _unref(t)('common.format.date.maskDateAndTime'),
            _unref(profileStore).userProfileSite?.site.timezone.ianaTimezone || '',
          )), 1),
        _createVNode(AppInformation, null, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_unref(t)('timezones.resultsRelease.tooltip')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}
}

})