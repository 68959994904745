import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { AppButton } from '@/plugins/commons';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { useI18n } from 'vue-i18n';

interface Emits {
  (e: 'discard'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppModalDiscard',
  props: {
    "modelValue": { type: Boolean, ...{
  required: true,
} },
    "modelModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["discard"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

const showModal = _useModel<boolean>(__props, "modelValue");

const { t } = useI18n();

const emit = __emit;

function handleDiscardClick(): void {
  emit('discard');
  showModal.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, {
    modelValue: showModal.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    "no-route-dismiss": "",
    persistent: ""
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('modal.header.saveChanges')), 1)
    ]),
    body: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('modal.body.saveChanges')), 1)
    ]),
    footer: _withCtx(({ dismiss }) => [
      _createVNode(_unref(AppButton), {
        type: "tertiary",
        "data-testid": "cancel-modal",
        label: _unref(t)('labels.common.cancel'),
        onClick: dismiss
      }, null, 8, ["label", "onClick"]),
      _createVNode(_unref(AppButton), {
        type: "danger",
        "data-testid": "dont-save-modal",
        label: _unref(t)('labels.common.discard'),
        onClick: handleDiscardClick
      }, null, 8, ["label"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})