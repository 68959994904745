import axios from '@/plugins/http/axios';
import { ApiResponse, Locale } from '@/shared/types/generic';
import {
  EditProfileRequestBody,
  LanguageCode,
  LockedOutResponse,
  ManagerProfile,
  ManagerProfileSite,
  ProfileUpdateRequest,
} from '@/shared/types/profile';

export default {
  getProfile(): Promise<ManagerProfile> {
    return axios.get('/profiles');
  },

  getProfileSite(): Promise<ManagerProfileSite> {
    return axios.get('/profiles/manager/site');
  },

  editProfile(requestBody: EditProfileRequestBody): Promise<void> {
    return axios.patch(`/profiles/manager`, requestBody);
  },

  updateProfile(request: ProfileUpdateRequest): Promise<ManagerProfile> {
    return axios.patch('/profiles', request);
  },

  updateFtx(request: ProfileUpdateRequest): Promise<void> {
    return axios.patch('/profiles/ftx', request);
  },

  getLanguagesCodes(): Promise<ApiResponse<LanguageCode[]>> {
    return axios.get('/languages/codes');
  },
  saveProfileLanguage(codeLanguage: Locale): Promise<void> {
    return axios.post(`/users/language/${codeLanguage}`);
  },
  getUserLockedOutInfo(email: string): Promise<LockedOutResponse> {
    return axios.get(`auth/locked-out/${email}`);
  },
};
