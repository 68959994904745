<template>
  <div class="app-currency-converter">
    <AppInput
      v-model="baseValue"
      class="app-currency-converter__input"
      data-testid="app-currency-converter-input"
      type="number"
      placeholder="0"
      :error="error"
      :format-numbers="coinsRelated"
    >
      <template #append>
        <span
          class="app-currency-converter__input__append"
          data-testid="app-currency-converter-input-append"
        >
          {{ getCurrencyCode(selectedCurrency.from) }}
        </span>
        <IconVaibeCoin
          v-if="selectedCurrency.from === Currency.COIN"
          class="app-currency-converter__coin"
        />
      </template>
    </AppInput>
    <AppButton
      class="app-currency-converter__exchange-button"
      data-testid="app-currency-converter-exchange-button"
      type="secondary"
      size="M"
      is-button-icon
      :ripple="false"
      @click="swapCurrencies"
    >
      <IconExchangeArrows height="18px" width="18px" />
    </AppButton>
    <AppInput
      v-model="convertedValue"
      class="app-currency-converter__input"
      data-testid="app-currency-converter-input-converted-value"
      placeholder="0"
      disable
      :debounce="debounce"
    >
      <template #append>
        <span
          class="app-currency-converter__input__append"
          data-testid="app-currency-converter-input-append-converted-value"
        >
          {{ getCurrencyCode(selectedCurrency.to) }}
        </span>
        <IconVaibeCoin
          v-if="selectedCurrency.to === Currency.COIN"
          class="app-currency-converter__coin"
        />
      </template>
    </AppInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Currency } from '@/shared/types/challenges';
import { mapStores } from 'pinia';
import { Range } from '@/shared/types/generic';
import useProfileStore from '@/store/profile/useProfileStore';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import { AppButton } from '@/plugins/commons';
import IconVaibeCoin from '@icons/icon-vaibe-coin.vue';
import IconExchangeArrows from '@/assets/icons/icon-exchange-arrows.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AppCurrencyConverter',

  components: {
    AppButton,
    AppInput,
    IconVaibeCoin,
    IconExchangeArrows,
  },

  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    error: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: undefined,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const { t, n } = useI18n();

    return { t, n };
  },

  data() {
    return {
      maxValue: 999999999,
      valueEuro: 0,
      selectedCurrency: {
        from: Currency.COIN,
        to: Currency.CUSTOM,
      } as Range<Currency>,
      Currency,
      coinsRelated: true,
    };
  },

  computed: {
    ...mapStores(useProfileStore),

    valueCoin: {
      get() {
        return this.modelValue;
      },

      set(newValue?: number) {
        this.$emit('update:modelValue', newValue || 0);
      },
    },

    baseValue: {
      get() {
        if (this.selectedCurrency.from === Currency.COIN) {
          return this.valueCoin;
        }

        return this.valueEuro;
      },

      set(newBaseValue: number) {
        if (newBaseValue > this.maxValue) {
          return;
        }
        if (this.selectedCurrency.from === Currency.COIN) {
          this.valueCoin = newBaseValue;
        } else {
          this.valueEuro = newBaseValue;
        }
      },
    },

    convertedValue: {
      get() {
        if (this.selectedCurrency.to === Currency.COIN) {
          return this.n(this.valueCoin);
        }

        return this.valueEuro;
      },

      set(newConvertedValue: number) {
        if (this.selectedCurrency.to === Currency.COIN) {
          this.valueCoin = newConvertedValue;
        } else {
          this.valueEuro = newConvertedValue;
        }
      },
    },
  },

  watch: {
    baseValue: {
      immediate: true,
      handler(newBaseValue: number, oldBaseValue: number) {
        if (this.profileStore.userProfile === undefined) {
          return;
        }

        const exchangeRate =
          this.selectedCurrency.from === Currency.COIN
            ? 1 / this.profileStore.userProfile.userConfig.exchangeRate
            : this.profileStore.userProfile.userConfig.exchangeRate;

        const result = parseFloat((newBaseValue * exchangeRate).toFixed(3));
        const coinValue =
          this.selectedCurrency.from === Currency.COIN ? newBaseValue : result;

        if (coinValue <= this.maxValue && Number.isInteger(coinValue)) {
          this.convertedValue = result;
        } else {
          this.baseValue = oldBaseValue;
        }
      },
    },
  },

  methods: {
    swapCurrencies() {
      [this.selectedCurrency.from, this.selectedCurrency.to] = [
        this.selectedCurrency.to,
        this.selectedCurrency.from,
      ];

      this.coinsRelated = !this.coinsRelated;
    },

    getCurrencyCode(currency: Currency) {
      if (currency === Currency.COIN) {
        return this.t(`enums.currency.${Currency.COIN}`);
      }

      if (this.profileStore.userProfile === undefined) {
        return '';
      }

      return this.profileStore.userProfile.userConfig.currencyCode;
    },
  },
});
</script>

<style scoped lang="scss">
.app-currency-converter {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.app-currency-converter__input {
  flex: 1;
}

.app-currency-converter__input__append {
  color: $gray-500;
  font-size: 14px;
  line-height: 150%;
}

.app-currency-converter__exchange-button {
  margin-top: 4px;
}

.app-currency-converter__coin {
  margin-left: 8px;
}
</style>
