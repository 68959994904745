export interface ChallengeKpisResponse {
  totalNumberOfParticipants: number;
  moneyInvested: number;
  moneySpent: number;
  averagePerHour: number;
  averagePerDay: number;
  mostProductiveDay: number;
  leastProductiveDay: number;
  bestPerformerName?: string;
}

export enum DashboardMetricChartDateInterval {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface DashboardMetricChart {
  histograms: {
    histogramPoints: {
      x: number;
      y: number | undefined;
    }[];
    participant: string;
  }[];
  totalUnits: number;
  chartDateInterval: DashboardMetricChartDateInterval;
}

export interface DashboardScheduleGanttRef {
  resetData: () => void;
}

export interface LineChartsRequest {
  sitesReferences: string[];
  participantsOrgIds: string[];
}

export interface BarChartRequest {
  sitesReferences: string[];
  participantsOrgIds: string[];
  detailsStartDate: number;
}
