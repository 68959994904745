import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

import { computed } from 'vue';
import { AppButtonToggleOption } from '@/shared/types/generic';
import { AppButtonSize } from '@/shared/types/components';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';
import AppField from '../AppField/AppField.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

interface Props {
  options: AppButtonToggleOption[];
  error?: string;
  disabled?: boolean;
  isolatedButtons?: boolean;
  size?: AppButtonSize;
  hideBottomSpace?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppButtonToggle',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    error: { default: undefined },
    disabled: { type: Boolean },
    isolatedButtons: { type: Boolean },
    size: { default: 'L' },
    hideBottomSpace: { type: Boolean, default: true }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<string | number>(__props, "modelValue");

const hasError = computed<boolean>(() => {
  return !!props.error && props.error.length > 0;
});

return (_ctx: any,_cache: any) => {
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!

  return (_openBlock(), _createBlock(AppField, {
    error: _ctx.error,
    "hide-bottom-space": _ctx.hideBottomSpace
  }, {
    error: _withCtx(() => [
      _createVNode(AppErrorMessage, { error: _ctx.error }, null, 8, ["error"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([
        'app-button-toggle',
        { 'app-button-toggle--isolated': _ctx.isolatedButtons },
        { 'app-button-toggle--grouped': !_ctx.isolatedButtons },
      ])
      }, [
        _createVNode(_component_q_btn_toggle, {
          modelValue: model.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
          options: _ctx.options,
          ripple: false,
          "toggle-color": null,
          "text-color": null,
          "toggle-text-color": null,
          class: _normalizeClass([
          'app-button-toggle__toggle',
          `app-button-toggle__toggle--${_ctx.size}`,
          { 'app-button-toggle__toggle--error': hasError.value },
        ]),
          disable: _ctx.disabled,
          unelevated: "",
          "data-testid": "app-button-toggle"
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.options.filter((opt) => !!opt.slot), (option) => {
            return {
              name: option.slot,
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, option.slot, {}, () => [
                  (option.tooltip)
                    ? (_openBlock(), _createBlock(AppTooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.tooltip), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ])
            }
          })
        ]), 1032, ["modelValue", "options", "class", "disable"])
      ], 2),
      _renderSlot(_ctx.$slots, "hintSpace", { class: "hint" })
    ]),
    _: 3
  }, 8, ["error", "hide-bottom-space"]))
}
}

})