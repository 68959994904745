import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "app-label" }
const _hoisted_2 = {
  key: 0,
  class: "app-label__label-asterisk"
}
const _hoisted_3 = {
  key: 0,
  class: "app-label__information"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInformation = _resolveComponent("AppInformation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "data-testid": "app-label__label",
      class: _normalizeClass([
        'app-label__label',
        `app-label__label--${_ctx.size}`,
        { 'app-label__label--required': _ctx.isRequired },
        { 'app-label__label--disabled': _ctx.disabled },
        { 'app-label__label--withoutBold': _ctx.withoutBold },
      ])
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
      (_ctx.hasAsterisk)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasInformation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AppInformation, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "information", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.information), 1)
              ], true)
            ]),
            _: 3
          })
        ]))
      : _renderSlot(_ctx.$slots, "trailing", { key: 1 }, undefined, true)
  ]))
}