import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-sidebar-row" }
const _hoisted_2 = { class: "app-sidebar-row__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "app-sidebar-row__content" }
const _hoisted_5 = { key: 0 }

import { useSlots } from 'vue';
import AppInformation from '../AppInformation/AppInformation.vue';

interface Props {
  title?: string;
  tooltipText?: string;
  content?: string | number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarRow',
  props: {
    title: {},
    tooltipText: {},
    content: {}
  },
  setup(__props: any) {

const props = __props;

const slots = useSlots();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title"),
      (!_unref(slots).title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.title), 1))
        : _createCommentVNode("", true),
      (props.tooltipText)
        ? (_openBlock(), _createBlock(AppInformation, {
            key: 1,
            size: "14px"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.tooltipText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "tooltip")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "content"),
      (!_unref(slots).content)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.content), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})