<template>
  <div class="app-date-picker" data-testid="app-date-picker">
    <AppInput
      v-model="model"
      data-testid="app-date-picker-input"
      :width="width"
      :placeholder="placeholder || $t('common.dates.placeholderShort')"
      :error="error"
      :disable="disabled"
      readonly
      @click="onFocusedInput"
    >
      <template #prepend>
        <AppIcon
          :name="outlinedEvent"
          class="app-date-picker__prepend-icon cursor-pointer"
          data-testid="app-date-picker-input-icon"
        >
          <q-popup-proxy
            v-model="showDatepicker"
            data-testid="app-date-picker-input-popup"
            transition-show="jump-down"
            transition-hide="jump-up"
            :offset="[20, 20]"
          >
            <AppDate
              v-model="model"
              :mask="mask"
              data-testid="app-date-picker-input-popup-date"
            >
              <div
                class="row items-center justify-end"
                data-testid="app-date-picker-input-popup-items"
              >
                <AppButton
                  v-close-popup
                  class="app-date-picker__close"
                  data-testid="app-date-picker-input-popup-close-button"
                  type="tertiary"
                  :label="$t('common.close')"
                />
              </div>
            </AppDate>
          </q-popup-proxy>
        </AppIcon>
        <span v-if="label" class="app-date-picker__prepend-label">
          {{ label }}
        </span>
      </template>
    </AppInput>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import AppDate from '@/components/app/AppDate/AppDate.vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import { outlinedEvent } from '@quasar/extras/material-icons-outlined';
import {
  formatDateToString,
  parseDate,
} from '@/shared/helpers/formatDates/formatDates';
import { mapStores } from 'pinia';
import useProfileStore from '@/store/profile/useProfileStore';
import { setInTimezone } from '@/shared/helpers/timezones/dateFns/dateFnsTimezones';
import { AppButton } from '@/plugins/commons';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AppDatePicker',

  components: {
    AppInput,
    AppDate,
    AppIcon,
    AppButton,
  },

  props: {
    modelValue: {
      type: Date,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const { t } = useI18n();

    return { t };
  },

  data() {
    return {
      showDatepicker: false,
      outlinedEvent,
      mask: this.t('common.format.input.maskDateShortEdit'),
      maskEdit: this.t('common.format.input.maskDateShort'),
      containerOverflow: undefined as HTMLElement | undefined,
    };
  },

  computed: {
    ...mapStores(useProfileStore),
    model: {
      get(): string {
        return this.modelValue
          ? formatDateToString(
              this.modelValue,
              this.maskEdit,
              this.profileStore?.userProfileSite?.site.timezone.ianaTimezone ||
                '',
            )
          : '';
      },

      set(newValue: string) {
        let inputDate = parseDate(
          newValue,
          this.maskEdit,
          this.modelValue || new Date(),
        );

        if (this.modelValue) {
          inputDate = setInTimezone(
            this.modelValue,
            inputDate.getFullYear(),
            inputDate.getMonth(),
            inputDate.getDate(),
            this.profileStore?.userProfileSite?.site.timezone.ianaTimezone ||
              '',
          );
        }

        this.$emit('update:modelValue', inputDate);
      },
    },
  },

  watch: {
    model() {
      this.showDatepicker = false;
    },
  },

  methods: {
    async onFocusedInput() {
      this.showDatepicker = true;
    },
  },
});
</script>

<style scoped lang="scss">
.app-date-picker {
  position: relative;
}

.app-date-picker .app-date {
  z-index: 5;
  position: absolute;
  top: calc(100% - 15px);
}

.app-date-picker__append {
  color: $gray-700;
}

.app-date-picker__prepend-icon {
  color: $gray-500;
  font-size: 20px;
}

.app-date-picker__prepend-label {
  color: $gray-500;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
}

.app-date-picker__close {
  min-height: 32px;
}

.app-date-picker__close :deep(.q-btn__content) {
  color: $gray-800;
}
</style>
