import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ftx-slide__get-started"
}
const _hoisted_2 = { class: "ftx-slide__title" }
const _hoisted_3 = { class: "ftx-slide__description" }

import { useI18n } from 'vue-i18n';
import { FTXSlide } from '@/shared/types/ftx';
import { computed } from 'vue';

interface Props {
  slide: FTXSlide;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FTXSlide',
  props: {
    slide: {}
  },
  setup(__props: any) {

const props = __props;

const { t } = useI18n();

const hasImageSlide = computed<boolean>(
  () => props.slide?.imageSlide !== undefined,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (hasImageSlide.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.slide.imageSlide), {
          key: 0,
          class: "ftx-slide__image"
        }))
      : _createCommentVNode("", true),
    (!hasImageSlide.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(t)('ftx.getStarted')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.slide.title), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.slide.description), 1)
  ], 64))
}
}

})