import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { type Component } from 'vue';
import IconWhiteboard from '@/assets/icons/icon-whiteboard.vue';

type EmptyStateType = 'default' | 'bold';

interface Props {
  text: string;
  icon?: Component;
  type?: EmptyStateType;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSimpleEmptyState',
  props: {
    text: {},
    icon: { default: IconWhiteboard },
    type: { default: 'default' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-simple-empty-state', `app-simple-empty-state--${_ctx.type}`])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon))),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 2))
}
}

})