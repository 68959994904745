import { ResponseMetadata } from '@/shared/types/generic';
import {
  MetricInputMethod,
  Metric,
  LastSearchMetricsResponse,
  ChartMetricFilter,
  ChartMetricsResponse,
  CategorizedMetrics,
  GroupedMetric,
  MetricCategoryType,
  MetricStatusStaticReference,
  MetricsCount,
  CalculationType,
} from '@/shared/types/metrics';
import i18n from '@/plugins/i18n/i18n';
import { getCategories } from '../categories/mockCategories';

interface Category {
  reference?: string;
  id?: number;
  name?: string;
}

const createMetrics = (
  reference: string,
  category: Category,
  statusReference: MetricStatusStaticReference,
  inputMethod: MetricInputMethod = MetricInputMethod.API,
  metricTypeName = 'metric.individual',
  metricTypeReference = 'ref-metric-individual',
): Metric[] =>
  new Array(1).fill(0).map((value, index) => ({
    reference,
    metricTypeName,
    unitMeasures: [{ name: 'Name 1', reference: 'reference 1' }],
    calculationTypes: [
      { name: 'Name 2', staticReference: CalculationType.AVG },
    ],
    transactionCode: '23476',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    name: `Metric ${index}`,
    inputMethod: {
      staticReference: inputMethod,
      name: i18n.global.t(`enums.metricInputMethod.${inputMethod}`),
    },
    status: {
      staticReference: statusReference,
      name: i18n.global.t(`enums.metricStatusReference.${statusReference}`),
    },
    category,
    hasGame: false,
    baseline: 5,
    metricTypeReference,
    safetyFeature: false,
    systemMetric: false,
  }));

export const metrics = [
  ...createMetrics(
    '0',
    {
      reference: 'Lorem ipsum 2',
      id: 2,
      name: `Lorem ipsum 2`,
    },
    MetricStatusStaticReference.ACTIVE,
    MetricInputMethod.FILE,
    'metric.individual',
    'ref-metric-individual',
  ),
  ...createMetrics(
    '1',
    {},
    MetricStatusStaticReference.INACTIVE,
    MetricInputMethod.FILE,
    'metric.individual',
    'ref-metric-individual',
  ),
  ...createMetrics(
    '2',
    {
      reference: 'Lorem ipsum 1',
      id: 1,
      name: `Lorem ipsum 1`,
    },
    MetricStatusStaticReference.ACTIVE,
    MetricInputMethod.FILE,
    'metric.individual',
    'ref-metric-individual',
  ),
  ...createMetrics(
    '3',
    {
      reference: '0',
      id: 0,
      name: `Category 0`,
    },
    MetricStatusStaticReference.ACTIVE,
    MetricInputMethod.FILE,
    'metric.individual',
    'ref-metric-individual',
  ),
  ...createMetrics(
    '3',
    {
      reference: 'Category 600',
      id: 600,
      name: `Category 600`,
    },
    MetricStatusStaticReference.ACTIVE,
    MetricInputMethod.FILE,
    'metric.individual',
    'ref-metric-individual',
  ),
];

const metricsLastMetric = metrics[metrics.length - 1];
metrics.push({
  ...metricsLastMetric,
  name: 'metric with duplicated category',
  transactionCode: '1234',
  category: {
    reference: 'other category 0',
    id: 0,
    name: `Category 0`,
  },
  metricTypeName: 'metric.team',
  metricTypeReference: 'ref-metric-team',
});

export const metadata: ResponseMetadata = {
  totalItems: metrics.length,
  lastUpdated: 1595030400000,
};

export const getMetrics = () => ({
  metadata,
  results: {
    metrics,
    totalMetrics: metrics.length,
    totalFilteredMetrics: metrics.length,
  },
});

export const getLastSearch = (): LastSearchMetricsResponse => ({
  chartData: {
    histogramPoints: [
      {
        x: 1685228400000,
        y: 330.0,
      },
      {
        x: 1685314800000,
        y: 330.0,
      },
      {
        x: 1685401200000,
        y: 330.0,
      },
      {
        x: 1685574000000,
        y: 330.0,
      },
      {
        x: 1685833200000,
        y: 330.0,
      },
      {
        x: 1686265200000,
        y: 330.0,
      },
      {
        x: 1686351600000,
        y: 330.0,
      },
      {
        x: 1686438000000,
        y: 330.0,
      },
      {
        x: 1686524400000,
        y: 430.0,
      },
      {
        x: 1686610800000,
        y: 430.0,
      },
      {
        x: 1687042800000,
        y: 330.0,
      },
      {
        x: 1687129200000,
        y: 330.0,
      },
      {
        x: 1687215600000,
        y: 330.0,
      },
      {
        x: 1687302000000,
        y: 330.0,
      },
    ],
    lastUpdatedPoint: 2115722400000,
  },
  searchParameters: {
    all: true,
    unitMeasureReference: 'ref-unit-measure',
    externalEntitiesIds: [
      '123654',
      'lin23',
      'test45678',
      'Teste 2',
      'WQA1',
      'WQA10',
      'WQA12',
      'WQA2',
      'WQA6',
      'WQA7',
    ],
    metricsTransactionCodes: ['1', 'ccc123', '1234567', '123', 'metgei1', '2f'],
    categoryReference: '6e7975c1-c8ef-40c2-bf08-d3b8034cc849',
    teamReference: '',
    dateInterval: ChartMetricFilter.MONTH,
  },
});

export const getChartData = (): ChartMetricsResponse => ({
  chartPoints: [
    {
      x: 1607558400000,
      y: 36670.0,
    },
    {
      x: 1681426800000,
      y: 1238130.0,
    },
    {
      x: 1681945200000,
      y: 375300.0,
    },
    {
      x: 1683846000000,
      y: 33530.0,
    },
    {
      x: 1684018800000,
      y: 210910.0,
    },
    {
      x: 1684105200000,
      y: 310.0,
    },
    {
      x: 1684364400000,
      y: 330.0,
    },
    {
      x: 1684450800000,
      y: 330.0,
    },
    {
      x: 1684796400000,
      y: 330.0,
    },
    {
      x: 1684882800000,
      y: 330.0,
    },
    {
      x: 1684969200000,
      y: 1.0,
    },
    {
      x: 1685055600000,
      y: 330.0,
    },
    {
      x: 1685228400000,
      y: 330.0,
    },
    {
      x: 1685314800000,
      y: 330.0,
    },
    {
      x: 1685401200000,
      y: 330.0,
    },
    {
      x: 1685574000000,
      y: 330.0,
    },
    {
      x: 1685833200000,
      y: 330.0,
    },
    {
      x: 1686265200000,
      y: 330.0,
    },
    {
      x: 1686351600000,
      y: 330.0,
    },
    {
      x: 1686438000000,
      y: 330.0,
    },
    {
      x: 1686524400000,
      y: 430.0,
    },
    {
      x: 1686610800000,
      y: 430.0,
    },
    {
      x: 1687042800000,
      y: 330.0,
    },
    {
      x: 1687129200000,
      y: 330.0,
    },
    {
      x: 1687215600000,
      y: 330.0,
    },
    {
      x: 1687302000000,
      y: 330.0,
    },
  ],
  lastUpdatedPoint: 2115722400000,
});

export const getMockCategorizedMetric = (): GroupedMetric => ({
  reference: (Math.random() + 1).toString(36).substring(2),
  name: 'Mock metric',
  transactionCode: (Math.random() + 1).toString(36).substring(2),
  type: {
    reference: (Math.random() + 1).toString(36).substring(2),
    name: 'metric.individual',
  },
});

export const mockCategorizedMetrics = (): CategorizedMetrics => ({
  categories: [
    {
      category: {
        name: 'Category 1',
        reference: 'category1',
        metrics: [getMockCategorizedMetric()],
        categoryType: {
          reference: 'ref-type-individual',
          name: 'category.individual',
        },
      },
    },
    {
      category: {
        name: 'Category 2',
        reference: 'category2',
        metrics: [getMockCategorizedMetric(), getMockCategorizedMetric()],
        categoryType: {
          reference: 'ref-type-individual',
          name: 'category.individual',
        },
      },
    },
  ],
  uncategorizedMetrics: [getMockCategorizedMetric()],
});

const types: MetricCategoryType[] = [
  { name: 'metric.individual', reference: 'ref-metric-individual' },
  { name: 'metric.team', reference: 'ref-metric-team' },
];

export const getMetricTypes = () => {
  return {
    metadata,
    results: {
      metricTypes: types,
    },
  };
};

const categoryTypes: MetricCategoryType[] = [
  { name: 'category.individual', reference: 'ref-category-individual' },
  { name: 'category.team', reference: 'ref-category-team' },
];

export const getCategoryTypes = () => {
  return {
    metadata,
    results: {
      categoryTypes,
    },
  };
};

export const getMockedMetricsCounters = (): MetricsCount => {
  return {
    numberOfMetrics: getMetrics().results.totalMetrics,
    numberOfCategories: getCategories().results.categories.length,
  };
};
