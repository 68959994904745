import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "new-password" }
const _hoisted_2 = {
  key: 0,
  class: "new-password__validations"
}
const _hoisted_3 = { class: "new-password__validations-title" }
const _hoisted_4 = { class: "new-password__validations-list" }

import { useI18n } from 'vue-i18n';
import { computed, reactive, watch } from 'vue';
import { PasswordErrors, Validation } from '@/shared/types/components';
import { validateRule } from '@/shared/helpers/password/changePasswordValidator/changePasswordValidator';
import AppLabel from '../AppLabel/AppLabel.vue';
import AppInput from '../AppInput/AppInput.vue';
import AppPasswordIcon from '../AppPasswordIcon/AppPasswordIcon.vue';
import AppValidationItem from '../AppValidationItem/AppValidationItem.vue';

enum PasswordType {
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
}

interface Props {
  errors: PasswordErrors;
  password: string;
  passwordConfirmation: string;
}

interface Emits {
  (e: 'update:errors', newModelValue: PasswordErrors): void;
  (e: 'update:password', newModelValue: string): void;
  (e: 'update:passwordConfirmation', newModelValue: string): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppNewPassword',
  props: {
    errors: {},
    password: {},
    passwordConfirmation: {}
  },
  emits: ["update:errors", "update:password", "update:passwordConfirmation"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const errors = computed<PasswordErrors>({
  get(): PasswordErrors {
    return props.errors;
  },

  set(newModelValue: PasswordErrors): void {
    emit('update:errors', newModelValue);
  },
});

const modelPassword = computed<string>({
  get(): string {
    return props.password;
  },

  set(newModelValue: string): void {
    emit('update:password', newModelValue);
  },
});

const modelPasswordConfirmation = computed<string>({
  get(): string {
    return props.passwordConfirmation;
  },

  set(newModelValue: string): void {
    emit('update:passwordConfirmation', newModelValue);
  },
});

const { t } = useI18n();

const state = reactive({
  showValidations: false,
  showPassword: {
    password: false,
    passwordConfirmation: false,
  },
});

const validations: Validation[] = [
  {
    text: t('changePassword.validate.minimumCharacters'),
    state: false,
    validation: /^[\w\W]{12,64}$/,
  },
  {
    text: t('changePassword.validate.uppercase'),
    state: false,
    validation: /(?=.*?[A-Z])(?=.*?[a-z])/,
  },
  {
    text: t('changePassword.validate.numberAndSpecialChar'),
    state: false,
    validation: /(?=.*?[0-9])(?=.*?[-@#$%^&*_+=[\]{}|\\:',?`~"()/!<>])/,
  },
  {
    text: t('changePassword.validate.consecutiveCharacters'),
    state: false,
    validation: /^(?!.*(.)\1).+$/,
  },
];

const getButtonPasswordType = (showPassword: boolean): string =>
  showPassword ? 'text' : 'password';

const togglePassword = (passwordType: PasswordType): void => {
  state.showPassword[passwordType] = !state.showPassword[passwordType];
};

const resetErrors = (): void => {
  errors.value.password = '';
  errors.value.passwordConfirmation = '';
};

watch(modelPassword, (newModelPasswordValue: string) => {
  resetErrors();
  validations.forEach((validation, index) => {
    validations[index].state = validateRule(
      newModelPasswordValue,
      validation.validation,
    );
  });
});

watch(modelPasswordConfirmation, () => {
  resetErrors();
});

__expose({
  modelPassword,
  modelPasswordConfirmation,
  state,
  getButtonPasswordType,
  togglePassword,
  resetErrors,
  validations,
  PasswordType,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(AppLabel, {
        class: "new-password__label",
        label: _unref(t)('changePassword.newPassword')
      }, null, 8, ["label"]),
      _createVNode(AppInput, {
        modelValue: modelPassword.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelPassword).value = $event)),
        name: "password",
        maxlength: 64,
        "format-numbers": false,
        error: errors.value.password,
        placeholder: _ctx.$t('changePassword.placeholders.newPassword'),
        type: getButtonPasswordType(state.showPassword.password),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (state.showValidations = true))
      }, {
        append: _withCtx(() => [
          _createVNode(AppPasswordIcon, {
            "password-has-errors": !!errors.value.password,
            "show-password": state.showPassword.password,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (togglePassword(PasswordType.password)))
          }, null, 8, ["password-has-errors", "show-password"])
        ]),
        _: 1
      }, 8, ["modelValue", "error", "placeholder", "type"])
    ]),
    (state.showValidations)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)('changePassword.mustContain')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(validations, (validation) => {
              return _createVNode(AppValidationItem, {
                key: validation.text,
                state: validation.state,
                text: validation.text
              }, null, 8, ["state", "text"])
            }), 64))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(AppLabel, {
        class: "new-password__label",
        label: _unref(t)('changePassword.confirmPassword')
      }, null, 8, ["label"]),
      _createVNode(AppInput, {
        modelValue: modelPasswordConfirmation.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((modelPasswordConfirmation).value = $event)),
        name: "password",
        maxlength: 64,
        "format-numbers": false,
        error: errors.value.passwordConfirmation,
        type: getButtonPasswordType(state.showPassword.passwordConfirmation),
        placeholder: _ctx.$t('changePassword.placeholders.newPasswordConfirmation')
      }, {
        append: _withCtx(() => [
          _createVNode(AppPasswordIcon, {
            "password-has-errors": !!errors.value.passwordConfirmation,
            "show-password": state.showPassword.passwordConfirmation,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (togglePassword(PasswordType.passwordConfirmation)))
          }, null, 8, ["password-has-errors", "show-password"])
        ]),
        _: 1
      }, 8, ["modelValue", "error", "type", "placeholder"])
    ])
  ]))
}
}

})