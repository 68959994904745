import axios from '@/plugins/http/axios';
import {
  DashboardMetricChart,
  ChallengeKpisResponse,
  LineChartsRequest,
  BarChartRequest,
} from '@/shared/types/dashboard';
import { ApiResponse } from '@/shared/types/generic';
import { Sites } from '@/shared/types/settings';
import { ChallengeParticipant } from '@/shared/types/user';
import { IChallenge } from '@/shared/types/wizard';
import qs from 'qs';

export default {
  getChartChallenges(
    startDate: number,
    endDate: number,
    page: number,
    size: number,
  ): Promise<
    ApiResponse<{ challenges: IChallenge[]; totalOfChallenges: number }>
  > {
    return axios.get('/challenges/chart', {
      params: { startTime: startDate, endTime: endDate, page, size },
    });
  },

  getChallengeKpis(challengeReference: string): Promise<ChallengeKpisResponse> {
    return axios.get(`/challenges/chart/kpis/${challengeReference}`);
  },

  getChallengeParticipants(
    gameReference: string,
    sitesReferences?: string[],
  ): Promise<ApiResponse<ChallengeParticipant[]>> {
    return axios.get(`/challenges/participants/${gameReference}`, {
      params: {
        sitesReferences,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  },

  getChallengeSites(gameReference: string): Promise<ApiResponse<Sites[]>> {
    return axios.get(`/sites/challenge/${gameReference}`);
  },

  getLineChart(
    gameReference: string,
    params: LineChartsRequest,
  ): Promise<DashboardMetricChart> {
    return axios.get(`/challenges/chart/${gameReference}`, {
      params,
      paramsSerializer: (parameters) =>
        qs.stringify(parameters, { arrayFormat: 'repeat' }),
    });
  },

  getBarChart(
    gameReference: string,
    params: BarChartRequest,
  ): Promise<DashboardMetricChart> {
    return axios.get(`/challenges/chart/details/${gameReference}`, {
      params,
      paramsSerializer: (parameters) =>
        qs.stringify(parameters, { arrayFormat: 'repeat' }),
    });
  },
};
