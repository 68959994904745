import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "app-progress-bar",
  "data-testid": "app-progress-bar"
}
const _hoisted_2 = {
  key: 0,
  class: "app-progress-bar__text",
  "data-testid": "app-progress-bar-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("span", {
      class: "app-progress-bar__indicator",
      "data-testid": "app-progress-bar-indicator"
    }, null, -1)),
    (_ctx.showSteps)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('common.step', [_ctx.model, _ctx.maxSteps])), 1))
      : _createCommentVNode("", true)
  ]))
}