import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__category" }
const _hoisted_3 = { class: "header__category-title" }
const _hoisted_4 = {
  key: 0,
  class: "header__category-actions"
}
const _hoisted_5 = { class: "header__item" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "header__item-details" }
const _hoisted_8 = {
  key: 0,
  class: "header__item-details--id"
}
const _hoisted_9 = { class: "header__item-details--name" }

import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import IconClear from '@/assets/icons/icon-clear.vue';
import { computed, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { SidebarTitle } from '@/shared/types/generic';
import { GRAY_600 } from '@/shared/constants/colors';
import { AppButton } from '@/plugins/commons';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

interface Props {
  itemType: SidebarTitle;
  itemTitle?: string;
  viewMode: boolean;
  itemUrl?: string;
  itemId?: string;
  hideHeaderContent?: boolean;
}

interface Emits {
  (e: 'close-sidebar'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarViewHeader',
  props: {
    itemType: {},
    itemTitle: { default: '' },
    viewMode: { type: Boolean },
    itemUrl: { default: '' },
    itemId: { default: '' },
    hideHeaderContent: { type: Boolean, default: false }
  },
  emits: ["close-sidebar"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const emit = __emit;

const props = __props;

function handleClose(): void {
  emit('close-sidebar');
}

const title = computed<string>(() => {
  return props.viewMode
    ? t(`common.sidebar.title.${props.itemType}`)
    : t(`common.sidebar.editTitle.${props.itemType}`);
});

const id = computed<string>(() => {
  return props.itemType === SidebarTitle.METRIC
    ? t('common.sidebar.tid', [props.itemId])
    : t('common.sidebar.id', [props.itemId]);
});

const slots = useSlots();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("b", _hoisted_3, _toDisplayString(title.value), 1),
      (_ctx.viewMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "actions"),
            (!!_unref(slots).actions)
              ? (_openBlock(), _createBlock(AppSeparator, {
                  key: 0,
                  vertical: "",
                  inset: ""
                }))
              : _createCommentVNode("", true),
            _createVNode(_unref(AppButton), {
              type: "tertiary",
              size: "S",
              "is-button-icon": "",
              onClick: handleClose
            }, {
              default: _withCtx(() => [
                _createVNode(IconClear, {
                  width: "16",
                  height: "16",
                  color: _unref(GRAY_600)
                }, null, 8, ["color"]),
                _createVNode(AppTooltip, {
                  anchor: "top middle",
                  self: "bottom middle",
                  offset: [5, 5]
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('common.close')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.hideHeaderContent && _ctx.viewMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.itemUrl)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass([
            'header__item-illustration',
            {
              'header__item-illustration--round':
                _ctx.itemType === _unref(SidebarTitle).USER,
            },
          ]),
                  src: props.itemUrl,
                  alt: "item cover image"
                }, null, 10, _hoisted_6))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.itemId)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(id.value), 1))
                : _createCommentVNode("", true),
              _createElementVNode("b", _hoisted_9, _toDisplayString(_ctx.itemTitle), 1),
              _renderSlot(_ctx.$slots, "details")
            ])
          ]),
          _createVNode(AppSeparator)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})