<script setup lang="ts">
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SidebarTitle } from '@/shared/types/generic';

const { t } = useI18n();

interface Props {
  itemType: SidebarTitle;
  itemTitle?: string;
  itemUrl?: string;
  itemId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  itemUrl: '',
  itemId: '',
  itemTitle: '',
});

const id = computed<string>(() => {
  return props.itemType === SidebarTitle.METRIC
    ? t('common.sidebar.tid', [props.itemId])
    : t('common.sidebar.id', [props.itemId]);
});
</script>

<template>
  <div class="app-sidebar-header-info">
    <div class="app-sidebar-header-info__item">
      <img
        v-if="itemUrl"
        :class="[
          'app-sidebar-header-info__item-illustration',
          {
            'app-sidebar-header-info__item-illustration--round':
              itemType === SidebarTitle.USER,
          },
        ]"
        :src="props.itemUrl"
        alt="item cover image"
      />
      <div class="app-sidebar-header-info__item-details">
        <span v-if="itemId" class="app-sidebar-header-info__item-details--id">
          {{ id }}
        </span>
        <b class="app-sidebar-header-info__item-details--name">
          {{ itemTitle }}
        </b>
        <slot name="details" />
      </div>
    </div>
    <AppSeparator />
  </div>
</template>

<style scoped lang="scss">
.app-sidebar-header-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.app-sidebar-header-info__item {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.app-sidebar-header-info__item-details {
  display: flex;
  flex-direction: column;
  color: $gray-800;
  font-size: 20px;
  width: 100%;
  overflow: hidden;
}

.app-sidebar-header-info__item-illustration {
  border-radius: 4px;
  height: 72px;
  width: 72px;
  object-fit: cover;
}

.app-sidebar-header-info__item-illustration--round {
  border-radius: 8px;
}

.app-sidebar-header-info__item-details--id {
  font-size: 12px;
  color: $gray-700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-sidebar-header-info__item-details--name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
