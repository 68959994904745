import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text--ellipsis" }
const _hoisted_2 = { class: "text--ellipsis app-date-table__time" }

import {
  DateFormat,
  formatDateTable,
} from '@/shared/helpers/formatDates/formatDates';

interface Props {
  date: number;
  ianaTimezone: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDateTable',
  props: {
    date: {},
    ianaTimezone: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(formatDateTable)(_ctx.date, _ctx.ianaTimezone, _unref(DateFormat).DATE)), 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(formatDateTable)(_ctx.date, _ctx.ianaTimezone, _unref(DateFormat).TIME)), 1)
  ]))
}
}

})