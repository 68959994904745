import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-status-indicator" }
const _hoisted_2 = { class: "app-status-indicator__container" }
const _hoisted_3 = { class: "app-status-indicator__container__content" }

import { computed, useSlots } from 'vue';
import { type Component } from 'vue';
import AppInformation from '../AppInformation/AppInformation.vue';

interface Props {
  backgroundColor: string;
  text: string;
  icon: Component;
  iconColor: string;
  percentage: number;
  backgroundProgressColor: string;
  textColor: string;
  tooltipMaxWidth: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppStatusIndicator',
  props: {
    backgroundColor: {},
    text: {},
    icon: {},
    iconColor: {},
    percentage: {},
    backgroundProgressColor: {},
    textColor: {},
    tooltipMaxWidth: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "694f5ed4": (_ctx.backgroundProgressColor),
  "9191f93c": (_ctx.textColor),
  "c7f0fab8": (percentageInString.value),
  "49f23b01": (_ctx.backgroundColor)
}))

const slots = useSlots();
const props = __props;

const percentageInString = computed(() => {
  return `${props.percentage}%`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(props.icon), {
          color: props.iconColor,
          class: "app-status-indicator__container__content-icon"
        }, null, 8, ["color"])),
        _createElementVNode("span", null, _toDisplayString(props.text), 1)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "app-status-indicator__container__progress" }, null, -1))
    ]),
    (_unref(slots).tooltip)
      ? (_openBlock(), _createBlock(AppInformation, {
          key: 0,
          size: "16px",
          class: "app-status-indicator__information",
          "tooltip-max-width": _ctx.tooltipMaxWidth
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "tooltip")
          ]),
          _: 3
        }, 8, ["tooltip-max-width"]))
      : _createCommentVNode("", true)
  ]))
}
}

})