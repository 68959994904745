import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-clickable"]

interface Props {
  maxWidth?: string;
  clickable?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppChip',
  props: {
    maxWidth: {},
    clickable: { type: Boolean }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "33f8478d": (_ctx.maxWidth)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "app-chip",
    "data-testid": "app-chip",
    "data-clickable": _ctx.clickable
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}
}

})