import { formatDateToString } from '@/shared/helpers/formatDates/formatDates';
import i18n from '@/plugins/i18n/i18n';

export default function getRetryLoginDate(
  epoch: number,
  timezone: string,
): string {
  return formatDateToString(
    epoch,
    i18n.global.t('common.format.input.maskTime'),
    timezone,
  );
}
