import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { matInfo } from '@quasar/extras/material-icons';
import AppIcon from '../AppIcon/AppIcon.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

interface Props {
  size?: string;
  tooltipMaxWidth?: string;
  self?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppInformation',
  props: {
    size: { default: '12px' },
    tooltipMaxWidth: { default: '340px' },
    self: { default: 'bottom middle' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppIcon, {
    class: "app-information",
    name: _unref(matInfo),
    size: props.size
  }, {
    default: _withCtx(() => [
      _createVNode(AppTooltip, {
        anchor: "top middle",
        self: props.self,
        offset: [10, 10],
        "max-width": _ctx.tooltipMaxWidth
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["self", "max-width"])
    ]),
    _: 3
  }, 8, ["name", "size"]))
}
}

})