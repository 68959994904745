import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: "placeholder",
  class: "app-select-section__placeholder"
}
const _hoisted_2 = {
  key: "filterLabel",
  class: "app-select-section__label"
}
const _hoisted_3 = {
  key: 0,
  class: "app-select__option__search-wrap app-select-section-search__border"
}
const _hoisted_4 = {
  key: 1,
  class: "app-select-group__container__all"
}
const _hoisted_5 = {
  key: 0,
  class: "app-select__option__search-wrap app-select-section-search__border"
}
const _hoisted_6 = { key: 1 }

import { computed, ref, watch } from 'vue';
import { AppSelectSize } from '@/shared/types/components';
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import handleSelectPopupShow from '@/shared/helpers/handleSelectPopupShow/handleSelectPopupShow';
import { SelectGroup } from '@/shared/types/generic';
import AppLoading from '@/components/app/AppLoading/AppLoading.vue';
import { useI18n } from 'vue-i18n';
import AppClearIcon from '../AppClearIcon/AppClearIcon.vue';
import AppSearch from '../AppSearch/AppSearch.vue';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';

interface Props {
  modelValue: Array<string>;
  options: SelectGroup[];
  inputDebounce?: number;
  popupContentClass?: string;
  width?: string;
  showSearch?: boolean;
  hideSelectAllOption?: boolean;
  isGroupsDisabled?: boolean;
  numberMaxSelection?: number;
  size?: AppSelectSize;
  clearable?: boolean;
  placeholder?: string;
  filterLabel?: string;
  localSearch?: boolean;
  isLoadingExternalSearch?: boolean;
  error?: string;
}

interface Emits {
  (e: 'update:modelValue', newModelValue: string[]): void;
  (e: 'externalSearch', value?: string): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSelectGrouped',
  props: {
    modelValue: {},
    options: {},
    inputDebounce: {},
    popupContentClass: {},
    width: {},
    showSearch: { type: Boolean },
    hideSelectAllOption: { type: Boolean },
    isGroupsDisabled: { type: Boolean },
    numberMaxSelection: {},
    size: {},
    clearable: { type: Boolean },
    placeholder: {},
    filterLabel: {},
    localSearch: { type: Boolean },
    isLoadingExternalSearch: { type: Boolean },
    error: {}
  },
  emits: ["update:modelValue", "externalSearch"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "3353cb3e": (_ctx.width)
}))

const props = __props;
const emit = __emit;
const { t } = useI18n();
const filter = ref('');

const model = computed<string[]>({
  get() {
    return props.modelValue;
  },

  set(newModelValue: string[]): void {
    emit('update:modelValue', newModelValue);
  },
});

const selectedItems = ref(model);

const showPlaceholder = computed<boolean>(() => {
  return props.placeholder !== undefined && model.value.length === 0;
});

const isSearchShown = computed<boolean>(() => {
  if (props.showSearch === undefined) {
    return props.options.length >= 7;
  }

  return props.showSearch;
});

const showClearable = computed<boolean>(() => {
  return props.clearable && model.value.length > 0;
});

const maxAllowedSelections = computed<boolean>(() => {
  return (
    props.numberMaxSelection !== undefined &&
    props.numberMaxSelection !== 0 &&
    selectedItems.value.length >= props.numberMaxSelection
  );
});

const showAllOption = computed<boolean>(() => {
  return props.options.length > 0 && !props.hideSelectAllOption;
});

const isError = computed<boolean | undefined>(() => {
  if ((props.error?.length || 0) > 0) {
    return true;
  }
  return undefined;
});

watch(filter, (newValue: string) => {
  if (newValue.length >= 3) {
    emit('externalSearch', newValue);
  }

  if (newValue.length === 0) {
    emit('externalSearch');
  }
});

function handleClear(e: Event): void {
  e.preventDefault();
  e.stopPropagation();
  model.value = [];
}

function isItemSelected(itemValue: string): boolean {
  const isSelected = model.value.find((selected) => selected === itemValue);
  return !!isSelected;
}

function canGroupSelection(group: SelectGroup): boolean {
  if (props.isGroupsDisabled && group.items && group.items?.length >= 0) {
    return false;
  }

  return true;
}

function isGroupSelected(group: SelectGroup): boolean | undefined {
  let atLeastOneItemSelected = false;
  let areAllItemsSelected = true;

  if (!group.items) {
    return isItemSelected(group.value);
  }

  if (group.items.length === 1) {
    return isItemSelected(group.items[0].value);
  }

  group.items.forEach((item) => {
    if (isItemSelected(item.value)) {
      atLeastOneItemSelected = true;
    } else if (!isItemSelected(item.value)) {
      areAllItemsSelected = false;
    }
  });

  if (atLeastOneItemSelected && !areAllItemsSelected) {
    return undefined;
  }

  return atLeastOneItemSelected && areAllItemsSelected;
}

function isSelectedAllGroups(): boolean | undefined {
  let allGroupSelected: boolean | undefined = true;

  props.options.forEach((group) => {
    if (!isGroupSelected(group)) {
      allGroupSelected = false;
    }
  });

  if (!allGroupSelected && model.value.length > 0) {
    allGroupSelected = undefined;
  }

  return allGroupSelected;
}

function filterGroupsAndItems(
  newFilter: string,
  options: SelectGroup[],
  showSearch: boolean,
): SelectGroup[] {
  if (newFilter.length === 0 && showSearch) {
    return options;
  }

  let filteredItemGroups = [] as SelectGroup[];

  filteredItemGroups = options.map((group) => {
    if (group.items) {
      const filteredItems = group.items.filter((item) =>
        item.name.toLowerCase().includes(newFilter.toLowerCase()),
      );

      return {
        ...group,
        items: filteredItems,
      };
    }
    return {
      ...group,
    };
  });

  if (
    filteredItemGroups.filter((group) => group.items && group.items.length > 0)
      .length === 0
  ) {
    return options.filter((group) =>
      group.groupLabel.toLowerCase().includes(newFilter.toLowerCase()),
    );
  }

  return filteredItemGroups.filter(
    (group) => group.items && group.items.length > 0,
  );
}

const filteredOptions = computed<SelectGroup[]>(() => {
  return filterGroupsAndItems(filter.value, props.options, isSearchShown.value);
});

function toggleAllItems(group: SelectGroup): void {
  group.items?.forEach((itemSelected) => {
    if (!isItemSelected(itemSelected.value)) {
      selectedItems.value.push(itemSelected.value);
    } else {
      const userIndex = selectedItems.value.findIndex(
        (selected) => selected === itemSelected.value,
      );
      selectedItems.value.splice(userIndex, 1);
    }
  });
  model.value = selectedItems.value;
}

function unselectAllSelectedItems(section: SelectGroup): void {
  section.items?.forEach((itemSelected) => {
    const hasSelectedIndex = selectedItems.value.findIndex(
      (item) => item === itemSelected.value,
    );
    if (hasSelectedIndex >= 0) {
      selectedItems.value.splice(hasSelectedIndex, 1);
    }
  });
  model.value = selectedItems.value;
}

function selectItem(itemValue: string): void {
  const itemSelectedIndex = model.value.findIndex(
    (selected) => selected === itemValue,
  );

  if (itemSelectedIndex < 0) {
    if (maxAllowedSelections.value) {
      return;
    }
    selectedItems.value.push(itemValue);
  } else {
    selectedItems.value.splice(itemSelectedIndex, 1);
  }

  model.value = selectedItems.value;
}

function selectItensInSection(group: SelectGroup): void {
  if (!group.items) {
    if (!isItemSelected(group.value)) {
      selectItem(group.value);
    } else {
      const userIndex = selectedItems.value.findIndex(
        (selected) => selected === group.value,
      );
      selectedItems.value.splice(userIndex, 1);
    }
  } else if (isGroupSelected(group) === undefined) {
    unselectAllSelectedItems(group);
  } else {
    toggleAllItems(group);
  }
}

function selectAllGroups(): void {
  if (isSelectedAllGroups() || isSelectedAllGroups() === undefined) {
    model.value = [];
  } else {
    props.options.forEach((group) => selectItensInSection(group));
  }
}

return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    ref: "qselect",
    modelValue: model.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model).value = $event)),
    options: _ctx.localSearch ? filteredOptions.value : _ctx.options,
    "dropdown-icon": _unref(symRoundedKeyboardArrowDown),
    "input-debounce": _ctx.inputDebounce,
    "virtual-scroll-item-size": 0,
    class: _normalizeClass([
      'app-select-section',
      `app-select-section--${_ctx.size}`,
      { 'app-select-section--width': _ctx.width },
    ]),
    "input-class": "app-select-section__input",
    "popup-content-class": `app-select__popup-content ${_ctx.popupContentClass} app-select__popup-content-grouped--all`,
    "options-dense": "",
    clearable: false,
    borderless: "",
    "no-error-icon": "",
    "data-testid": "app-select-input",
    error: isError.value,
    onPopupShow: _cache[5] || (_cache[5] = ($event: any) => (_unref(handleSelectPopupShow)(_ctx.$el, '--appSelectMaxWidth')))
  }, _createSlots({
    append: _withCtx(() => [
      (_ctx.$slots.append)
        ? _renderSlot(_ctx.$slots, "before-options", { key: 0 })
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (showClearable.value)
              ? (_openBlock(), _createBlock(AppClearIcon, {
                  key: 0,
                  onClick: handleClear
                }))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    "before-options": _withCtx(() => [
      (isSearchShown.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(AppSearch, {
              modelValue: filter.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
              "select-search": ""
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      (showAllOption.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_q_item, {
              clickable: "",
              "model-value": isSelectedAllGroups(),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (selectAllGroups()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      "model-value": isSelectedAllGroups(),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (selectAllGroups()))
                    }, null, 8, ["model-value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item_section, { class: "app-select-group__label" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, { class: "app-select-grouped__title" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('common.filters.all')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model-value"]),
            _createVNode(AppSeparator, { class: "app-select-section__separator" })
          ]))
        : _createCommentVNode("", true)
    ]),
    "no-option": _withCtx(() => [
      (isSearchShown.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(AppSearch, {
              modelValue: filter.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter).value = $event)),
              "select-search": ""
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_item, { class: "q-item--dense app-select__option app-select__option--not-found" }, {
        default: _withCtx(() => [
          (_ctx.isLoadingExternalSearch)
            ? (_openBlock(), _createBlock(AppLoading, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.filters.noOptionFound')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    option: _withCtx(({ opt, index }) => [
      (_ctx.isLoadingExternalSearch)
        ? (_openBlock(), _createBlock(AppLoading, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (index !== 0)
              ? (_openBlock(), _createBlock(AppSeparator, {
                  key: 0,
                  class: "app-select-section__separator"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_item, {
              clickable: "",
              disable: !canGroupSelection(opt),
              "model-value": isGroupSelected(opt),
              onClick: ($event: any) => (selectItensInSection(opt))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    (canGroupSelection(opt))
                      ? (_openBlock(), _createBlock(_component_q_checkbox, {
                          key: index,
                          "model-value": isGroupSelected(opt),
                          onClick: ($event: any) => (selectItensInSection(opt))
                        }, null, 8, ["model-value", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_q_item_section, { class: "app-select-group__label" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, {
                      class: _normalizeClass(['app-select-grouped__title', {}])
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("b", null, _toDisplayString(opt.groupLabel), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_item_label, { class: "app-select-group__label--append" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(opt.groupAppendLabel), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["disable", "model-value", "onClick"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opt.items, (subItem) => {
              return (_openBlock(), _createBlock(_component_q_item, {
                key: subItem.value,
                clickable: "",
                "model-value": isItemSelected(subItem.value),
                onClick: ($event: any) => (selectItem(subItem.value))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_q_checkbox, {
                        key: subItem.value,
                        "model-value": isItemSelected(subItem.value),
                        onClick: ($event: any) => (selectItem(subItem.value))
                      }, null, 8, ["model-value", "onClick"]))
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(subItem.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["model-value", "onClick"]))
            }), 128))
          ]))
    ]),
    _: 2
  }, [
    (showPlaceholder.value)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.placeholder), 1)
          ]),
          key: "0"
        }
      : (_ctx.filterLabel)
        ? {
            name: "selected",
            fn: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.filterLabel || _ctx.$t('common.filters.options', model.value.length)), 1)
            ]),
            key: "1"
          }
        : undefined,
    (_ctx.error)
      ? {
          name: "error",
          fn: _withCtx(() => [
            _createVNode(AppErrorMessage, { error: _ctx.error }, null, 8, ["error"])
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["modelValue", "options", "dropdown-icon", "input-debounce", "class", "popup-content-class", "error"]))
}
}

})