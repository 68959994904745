import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { RouterLinkProps } from 'vue-router';

interface Props extends RouterLinkProps {
  label: string;
  tooltipText?: string;
  showAsLink?: boolean;
  target?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppConditionalLink',
  props: {
    label: {},
    tooltipText: {},
    showAsLink: { type: Boolean },
    target: {},
    custom: { type: Boolean },
    activeClass: {},
    exactActiveClass: {},
    ariaCurrentValue: {},
    to: {},
    replace: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showAsLink)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        target: _ctx.target,
        class: "conditional-link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.tooltipText)
            ? (_openBlock(), _createBlock(_unref(BaseTooltip), { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.tooltipText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to", "target"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
}
}

})