import { Image } from './generic';
import { RoleLevelSimplified } from './roles';

export enum UserType {
  OPERATOR = 'UT_1',
  MANAGER = 'UT_2',
  ADMIN = 'UT_3',
}

export enum UserStatus {
  ACTIVE = 'S_1',
  DELETED = 'S_2',
  DISABLED = 'S_18',
  INACTIVE = 'S_13',
  INVITED = 'S_17',
}

export interface Status {
  reference: string;
  name: string;
}

export interface SiteUser {
  name: string;
  reference: string;
  city: string;
  country: string;
  timezone: {
    utcOffset: string;
    ianaTimezone: string;
  };
}

export interface IUserBase {
  name: string;
  reference: string;
  orgUserId?: string;
  avatarUrl: string;
  email: string;
  userTypeStaticReference: UserType;
  userStatusStaticReference: UserStatus;
  site: SiteUser;
  lastEntrance?: number;
}

export interface IUser extends IUserBase {
  numberOfChallenges: number;
  teamNames: string;
  team?: {
    reference: string;
    name: string;
  };
  termsAndConditions: boolean;
  privacyPolicy: boolean;
  coins: number;
  points: number;
}

export interface IManager extends IUserBase {
  roleLevel: RoleLevelSimplified;
  numberOfManagedTeams: number;
  numberOfManagedSites: number;
}

export interface RegisterUser {
  email: string;
  name: string;
  orgUserId: string;
}

export interface EditUser {
  name: string;
  email?: string;
  orgUserId?: string;
  userTypeStaticReference: UserType;
  userStatusStaticReference: UserStatus;
  team?: {
    reference: string;
    name: string;
  };
  teamReference: string | undefined;
  reference: string;
  siteReference: string;
}

export interface UserSidebarEditErrors {
  name: string;
  orgUserId: string;
  siteReference: string;
}

export interface EditTeamErrors {
  name: string;
  orgTeamId: string;
}

export interface EditTeamRequest {
  name: string;
  orgTeamId: string;
  description: string;
  imageReference?: string;
}

export interface NuvoUserRow {
  row: number;
  name: string;
  email: string;
  orgUserId?: string;
  userTypeStaticReference?: UserType;
  siteExternalReference: string;
  roleLevelReference?: string;
}

export interface UserErrorListItem {
  code: number;
  message: string;
  field:
    | 'userTypeStaticReference'
    | 'orgUserId'
    | 'email'
    | 'name'
    | 'siteExternalReference'
    | 'roleId'
    | 'roleLevelReference';
}

export interface UserError {
  row: number;
  list: UserErrorListItem[];
}

export interface ChallengeParticipant {
  participantOrgId: string;
  name: string;
}

export interface Operator {
  orgUserId?: string;
  reference: string;
  name: string;
}

export interface NewTeam {
  name: string;
  imageReference?: string;
  orgTeamId: string;
  description: string;
}

export interface NewTeamErrors {
  name: string;
  orgTeamId: string;
}

export interface Team {
  name: string;
  reference: string;
  image: Image;
  description?: string;
  orgTeamId?: string;
  numberOfUsers?: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface ChangeTeamRequest {
  userReferences: string[];
  teamReference: string;
}

export interface ChangeSiteRequest {
  userReferences: string[];
  siteReference: string;
}

export interface CreateUserModel {
  name: string;
  orgUserId: string;
  email: string;
  userTypeStaticReference?: UserType;
  teamReference: string;
  siteReference: string;
  roleLevelReference: string;
  managedSitesReferences: string[];
  managedTeamsReferences: string[];
}

export interface CreateUserRequestBody {
  name: string;
  orgUserId?: string;
  email: string;
  userTypeStaticReference?: UserType;
  teamReference?: string;
  siteReference: string;
  roleLevelReference?: string;
}

export interface CreateUserErrors {
  name: string;
  orgUserId: string;
  email: string;
  userTypeStaticReference: string;
  siteReference: string;
  roleLevelReference: string;
}

export interface GetAllValidOperatorsResponse {
  teams: Array<{
    name: string;
    orgTeamId: string;
    reference: string;
    operators: Array<{
      name: string;
      orgUserId: string;
    }>;
  }>;
  operatorsWithNoTeams: Array<{
    name: string;
    orgUserId: string;
  }>;
}

export interface RequestUsersTimezones {
  workerReferences?: string[];
  teamReferences?: string[];
  audience?: string;
  startDate?: number;
  endDate?: number;
}

export interface UserInfo {
  name: string;
  reference: string;
  orgUserId: string;
}

export interface UsersSites {
  siteName: string;
  siteCity: string;
  siteCountry: string;
  siteOffset: string;
  users: UserInfo[];
}

export interface LoadUsersRequest {
  page: number;
  size: number;
  teams?: string[];
  roles?: UserType[];
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
  statuses?: UserStatus[];
  noTeams: boolean;
  sites?: string[];
}

export interface LoadUsersResponse {
  users: IUser[];
  numberOfUsers: number;
  numberOfUsersFiltered: number;
}

export interface UsersCount {
  numberOfPlayers: number;
  numberOfTeams: number;
  numberOfManagers: number;
}

export interface LoadManagersRequest {
  page: number;
  size: number;
  query?: string;
  orderBy?: 'ASC' | 'DESC';
  sortBy?: string;
  statuses?: UserStatus[];
  roleLevels?: string[];
  sites?: string[];
  teams?: string[];
  managerSites?: string[];
}

export interface LoadManagersResponse {
  managers: IManager[];
  numberOfManagers: number;
  numberOfManagersFiltered: number;
}

export interface EditManager {
  name: string;
  email?: string;
  orgUserId?: string;
  userTypeStaticReference: UserType;
  userStatusStaticReference: UserStatus;
  siteReference: string;
  roleLevelReference: string;
  managedSitesReferences: string[];
  managedTeamsReferences: string[];
}

export interface ManagerSidebarEditErrors {
  name: string;
  orgUserId: string;
  siteReference: string;
  roleLevelReference: string;
}

export interface EditUserRequest
  extends Partial<EditUser>,
    Partial<EditManager> {
  siteReference: string;
}

export interface ChangeRoleLevelBulkRequest {
  userReferences: string[];
  roleLevelReference: string;
}

export interface TeamsParticipants {
  name: string;
  reference: string;
  avatar: string;
  utcOffset: string;
}
export interface ManagerTeamsParticipantsDetail {
  name: string;
  avatar: string;
  reference: string;
  participants: Array<TeamsParticipants>;
}

export interface ManagerDetailTeamsDetail {
  numberOfParticipants: number;
  numberOfTeams?: number;
  numberOfSites?: number;
  timezoneDifference: number;
  utcOffset: string;
  teams?: Array<ManagerTeamsParticipantsDetail>;
  sites?: Array<ManagerTeamsParticipantsDetail>;
}

export enum ModalDetailsType {
  TEAMS = 'teams',
  SITES = 'sites',
}

export interface ManagedItem {
  reference: string;
  name: string;
}

export interface ManagedItemsResponse {
  managedSites: ManagedItem[];
  managedTeams: ManagedItem[];
}

export interface ParamsSimplifiedTeams {
  onlyTeamsWithOperators?: boolean;
  disableFilter?: boolean;
}

export interface ParamsUsersSite {
  filterBy?: string;
  disableFilter?: boolean;
}
