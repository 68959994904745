import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-currency-converter" }
const _hoisted_2 = {
  key: 1,
  class: "app-currency-converter__input__append"
}
const _hoisted_3 = {
  key: 0,
  class: "app-currency-converter__input__append"
}

import { computed, reactive, ref, watch } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import { AppButton } from '@/plugins/commons';
import IconVaibeCoin from '@icons/icon-vaibe-coin.vue';
import IconExchangeArrows from '@/assets/icons/icon-exchange-arrows.vue';
import { useI18n } from 'vue-i18n';
import { GRAY_700 } from '@/shared/constants/colors';
import {
  calculateCoinValue,
  calculateCurrencyValue,
} from '@/shared/helpers/moneyCalculations/moneyCalculations';
import AppLabel from '../AppLabel/AppLabel.vue';

const maxCoinAmount = 999999999;

interface Props {
  error: string;
}

interface State {
  rightInput: string | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppCoinConverter',
  props: /*@__PURE__*/_mergeModels({
    error: {}
  }, {
    "amount": {
  required: true,
},
    "amountModifiers": {},
    "coinsToCurrency": { type: Boolean, ...{
  required: true,
} },
    "coinsToCurrencyModifiers": {},
  }),
  emits: ["update:amount", "update:coinsToCurrency"],
  setup(__props: any) {

const profileStore = useProfileStore();

const { t } = useI18n();
const inputElement = ref();

const leftInput = _useModel<string>(__props, 'amount');

const coinsToCurrency = _useModel<boolean>(__props, 'coinsToCurrency');

const state = reactive<State>({
  rightInput: '0',
});

const props = __props;

function swapCurrencies(): void {
  coinsToCurrency.value = !coinsToCurrency.value;
  const right = state.rightInput;
  const left = leftInput.value;
  leftInput.value = right || '0';
  state.rightInput = left;
}

const leftInputLabel = computed<string>(() => {
  return coinsToCurrency.value
    ? t('games.challengeWizard.steps.prize.coinsAmount')
    : t('games.challengeWizard.steps.prize.currencyInvestment', [
        t(
          `games.prizeDistribution.prize.money.${profileStore.userProfile?.userConfig.currencyCode}`,
        ),
      ]);
});

const rightInputLabel = computed<string>(() => {
  return coinsToCurrency.value
    ? t('games.challengeWizard.steps.prize.currencyInvestment', [
        t(
          `games.prizeDistribution.prize.money.${profileStore.userProfile?.userConfig.currencyCode}`,
        ),
      ])
    : t('games.challengeWizard.steps.prize.coinsAmount');
});

function selectText() {
  inputElement.value.$el.querySelector('input').select();
}

function sanitizeInput(value: string): string {
  return value.slice(0, 9);
}

watch(
  () => leftInput.value,
  (newValue: string, oldValue?: string) => {
    if (coinsToCurrency.value) {
      if (!Number.isInteger(Number(newValue)) && oldValue) {
        leftInput.value = oldValue;
        return;
      }

      if (newValue.toString() === '') {
        state.rightInput = undefined;
        return;
      }

      state.rightInput = String(
        calculateCurrencyValue(
          Number(newValue),
          profileStore.userProfile?.userConfig.exchangeRate,
        ),
      );
    } else {
      const coinsAmount = calculateCoinValue(
        Number(newValue),
        profileStore.userProfile?.userConfig.exchangeRate,
      );

      if (!Number.isInteger(coinsAmount) && !!oldValue) {
        leftInput.value = oldValue;
        return;
      }

      if (newValue.toString() === '') {
        state.rightInput = undefined;
        return;
      }

      const rightInputValue =
        coinsAmount >= maxCoinAmount ? newValue : String(coinsAmount);

      state.rightInput = rightInputValue;

      const leftInputValue =
        coinsAmount >= maxCoinAmount ? String(oldValue) : newValue;

      leftInput.value = sanitizeInput(leftInputValue);
    }
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(AppLabel, { label: leftInputLabel.value }, null, 8, ["label"]),
      _createVNode(AppInput, {
        ref_key: "inputElement",
        ref: inputElement,
        modelValue: leftInput.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((leftInput).value = $event)),
        class: "app-currency-converter__input",
        type: "number",
        maxlength: 9,
        placeholder: 
          _unref(t)(
            'games.challengeWizard.steps.participantsGoal.goal.dropdown.placeholder.amount',
          )
        ,
        width: "200px",
        error: props.error,
        onFocus: selectText
      }, {
        append: _withCtx(() => [
          (coinsToCurrency.value)
            ? (_openBlock(), _createBlock(IconVaibeCoin, {
                key: 0,
                class: "app-currency-converter__coin"
              }))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(profileStore).userProfile?.userConfig.currencyCode), 1))
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "error"])
    ]),
    _createVNode(_unref(AppButton), {
      class: "app-currency-converter__exchange-button",
      type: "secondary",
      "is-button-icon": "",
      ripple: false,
      onClick: swapCurrencies
    }, {
      default: _withCtx(() => [
        _createVNode(IconExchangeArrows, {
          height: "18px",
          width: "18px",
          color: _unref(GRAY_700)
        }, null, 8, ["color"])
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(AppLabel, { label: rightInputLabel.value }, null, 8, ["label"]),
      _createVNode(AppInput, {
        modelValue: state.rightInput,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.rightInput) = $event)),
        type: "number",
        class: "app-currency-converter__input",
        disable: "",
        width: "200px"
      }, {
        append: _withCtx(() => [
          (coinsToCurrency.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(profileStore).userProfile?.userConfig.currencyCode), 1))
            : (_openBlock(), _createBlock(IconVaibeCoin, {
                key: 1,
                class: "app-currency-converter__coin"
              }))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}
}

})