export default {
  globals: {
    fontFamily: 'Nunito, sans-serif',
    primaryTextColor: '#262523',
  },
  header: {
    title: {
      fontSize: '24px',
      color: '#262523',
      fontWeight: 'normal',
      letterSpacing: '0.02em',
      margin: 0,
    },
    description: {
      color: '#8f887e',
    },
  },
  buttons: {
    primary: {
      height: '32px',
      padding: '6px 38px',
      backgroundColor: '#0060ff',
      borderRadius: '20px',
      color: '#fff',
      fontWeight: 700,
    },
    secondary: {
      borderRadius: '20px',
      height: '32px',
      padding: '6px 38px',
      fontWeight: 700,
    },
    tertiary: {
      borderRadius: '20px',
      height: '32px',
      padding: '6px 38px',
      fontWeight: 700,
    },
  },
  loader: {
    loadAnimationColor: '#0060ff',
  },
  dropzone: {
    icon: {
      box: {
        fill: '#0060ff',
      },
    },
  },
  sheetSelect: {
    grid: {
      checked: {
        root: {
          borderColor: '#0060ff',
        },
        checkbox: {
          backgroundColor: '#0060ff',
        },
      },
    },
  },
  headerSelect: {
    content: {
      fontSize: '12px',
    },
    sheetName: {
      title: {
        fontSize: '24px',
      },
      description: {
        color: '#8f887e',
        fontSize: '14px',
      },
      border: {
        backgroundColor: '#0060ff',
      },
    },
  },
  progressBar: {
    complete: {
      color: '#bfb8af',
      fontWeight: 'normal',
    },
    incomplete: {
      color: '#bfb8af',
      fontWeight: 'normal',
    },
    current: {
      color: '#262523',
      fontWeight: 'normal',
    },
  },
  columnMatch: {
    columnMatchHeader: {
      root: {
        backgroundColor: '#f7f9fa',
        borderColor: '#E6E2DC',
      },
      dropdown: {
        search: {},
        selectedOption: {
          backgroundColor: '#E6E2DC',
        },
        button: {
          root: {
            color: '#262523',
            borderColor: '#BFB8AF',
          },
          icon: {
            color: '#262523',
          },
        },
        header: {
          backgroundColor: '#f7f9fa',
        },
        option: {
          color: '#262523',
          ':hover': {
            backgroundColor: '#E6E2DC',
          },
        },
      },
    },
    columnMatchValue: {
      root: {
        backgroundColor: '#FFFFFF',
        borderColor: '#E6E2DC',
      },
    },
  },
  reviewEntries: {
    table: {
      td: {
        warning: {
          backgroundColor: '#fff2d7',
          color: '#262523',
        },
        error: {
          backgroundColor: '#f5d7cc',
          color: '#262523',
        },
      },
    },
  },
  dialog: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  popoverInfo: {
    backgroundColor: '#0060ff',
    color: 'white',
    fontSize: '14px',
  },
};
