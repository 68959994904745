<script setup lang="ts">
import { computed, ref } from 'vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import handleSelectPopupShow from '@/shared/helpers/handleSelectPopupShow/handleSelectPopupShow';
import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { AppSelectSize } from '@/shared/types/components';
import { SelectGroupItem } from '@/shared/types/generic';
import { QSelect } from 'quasar';
import { ChallengeFormat } from '@/shared/types/wizard';
import AppSelect from '@/components/app/AppSelect/AppSelect.vue';
import { BaseTooltip } from '@vaibe-github-enterprise/kd-kse-fe-commons';

interface Props {
  inputDebounce?: number;
  width?: string;
  size?: AppSelectSize;
  placeholder?: string;
  error?: string;
  options: unknown[];
  selectLabel: string;
}

const model = defineModel<string>({
  required: true,
});

const props = withDefaults(defineProps<Props>(), {
  inputDebounce: 300,
  width: '296px',
  size: 'M',
  placeholder: '',
  error: '',
});

const qselect = ref<QSelect | null>(null);

const showPlaceholder = computed<boolean>(() => {
  return props.placeholder !== undefined && !model.value;
});

function isItemSelected(item: SelectGroupItem): boolean {
  return item.value === model.value;
}

function hidePopup() {
  qselect.value?.hidePopup();
}

function selectItem(item: SelectGroupItem): void {
  model.value = item.value as ChallengeFormat;
  hidePopup();
}

defineExpose({
  hidePopup,
  selectItem,
  isItemSelected,
});
</script>

<template>
  <AppSelect
    v-bind="$props"
    ref="qselect"
    v-model="model"
    :options="options"
    :dropdown-icon="symRoundedKeyboardArrowDown"
    :input-debounce="inputDebounce"
    :class="[
      'app-select-hierarchical',
      `app-select-hierarchical--${size}`,
      { 'app-select-hierarchical--width': width },
    ]"
    input-class="app-select-section__input"
    :popup-content-class="`app-select__popup-content app-select__popup-content-grouped--all`"
    options-dense
    emit-value
    borderless
    :error="error"
    no-error-icon
    bottom-slots
    @popup-show="handleSelectPopupShow($el, '--appSelectMaxWidth')"
  >
    <template v-if="showPlaceholder" #selected>
      <span key="placeholder" class="app-select-hierarchical_placeholder">
        {{ placeholder }}
      </span>
    </template>
    <template #selected-item>
      <span>
        {{ selectLabel }}
      </span>
    </template>
    <template #option="{ opt }">
      <div>
        <q-item-section class="app-select-hierarchical__label">
          <q-item-label class="app-select-hierarchical__type-title">
            <span
              :class="[
                'app-select-hierarchical__label-type',
                { 'app-select-hierarchical__label-disabled': opt.disable },
              ]"
            >
              {{ opt.label }}
            </span>
          </q-item-label>
        </q-item-section>
        <AppSeparator class="app-select-hierarchical__separator" />

        <q-item
          v-for="subItem in opt.items"
          :key="subItem.value"
          clickable
          :class="[
            {
              'app-select-section__item-selected': subItem.value === model,
            },
          ]"
          :disable="subItem.disable"
          :model-value="isItemSelected(subItem)"
          @click="selectItem(subItem)"
        >
          <BaseTooltip v-if="subItem.disableTooltip">
            {{ subItem.disableTooltip }}
          </BaseTooltip>
          <q-item-section>
            <q-item-label class="app-select-hierarchical__item-container">
              <span class="app-select-hierarchical__label-mode">
                {{ subItem.label }}
              </span>
              <span class="app-select-hierarchical__label-mode-description">
                {{ subItem.valueDescription }}
              </span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>
  </AppSelect>
</template>

<style scoped lang="scss">
.app-select-hierarchical :deep(.q-field__control) {
  border-radius: 4px;
  padding: 1px;
  border: 1px solid $gray-400;
  background-color: $white;
  box-sizing: border-box;
}

.app-select-hierarchical--M :deep(.q-field__native) {
  height: 44px;
}

.app-select-hierarchical--M :deep(.app-select-section__label),
.app-select-hierarchical--M :deep(.app-select-section__input),
.app-select-hierarchical--M :deep(.app-select-hierarchical_placeholder) {
  line-height: 34px;
}

.app-select-hierarchical :deep(.app-search .app-search__input) {
  flex-grow: 1;
}

.app-select-hierarchical:hover :deep(.q-field__control) {
  border: 1px solid $blue-400;
}

.app-select-hierarchical.q-field--error :deep(.q-field__control) {
  border: 1px solid $red-500;
}

.app-select-hierarchical.q-field--focused :deep(.q-field__control) {
  border: 1px solid $blue-500;
}

.app-select-hierarchical :deep(.q-field__native) {
  padding: 5px 0 5px 12px;
  align-items: center;
}

.app-select-hierarchical :deep(.q-field__append) {
  height: unset;
  padding: 0 8px;
  color: $gray-800;
}

.app-select-hierarchical--width {
  max-width: v-bind(width);
}

.app-select-hierarchical_placeholder {
  color: $gray-500;
}

.app-select-section__item-selected {
  background: $gray-25;
}

.app-select-section__label,
.app-select-hierarchical_placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-select-hierarchical.q-field--disabled :deep(.q-field__control) {
  background-color: $gray-50;
  border-color: $gray-50;
}

.app-select-hierarchical__label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.app-select-hierarchical__type-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 6px;
  width: 172px;
}

.app-select-hierarchical__label-type {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: $gray-800;
  line-height: 18px;
}

.app-select-hierarchical__label-disabled {
  color: $gray-400;
  font-weight: 100;
}

.app-select-hierarchical__label-mode {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: $gray-800;
}

.app-select-hierarchical__label-mode-description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: $gray-500;
}

.app-select-hierarchical__item-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.app-select-hierarchical__separator {
  background-color: $gray-100;
}
</style>
