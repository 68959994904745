import {
  isSameDay,
  isSameWeek,
  isSameYear,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  set,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const isTodayTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone ? isToday(date) : isToday(utcToZonedTime(date, timezone));
};
export const isYesterdayTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isYesterday(date)
    : isYesterday(utcToZonedTime(date, timezone));
};
export const isThisWeekTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isThisWeek(date)
    : isThisWeek(utcToZonedTime(date, timezone));
};
export const isThisYearTimezone = (
  date: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isThisYear(date)
    : isThisYear(utcToZonedTime(date, timezone));
};
export const isSameDayTimezone = (
  dateLeft: Date | number,
  dateRight: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isSameDay(dateLeft, dateRight)
    : isSameDay(
        utcToZonedTime(dateLeft, timezone),
        utcToZonedTime(dateRight, timezone),
      );
};
export const isSameWeekTimezone = (
  dateLeft: Date | number,
  dateRight: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isSameWeek(dateLeft, dateRight)
    : isSameWeek(
        utcToZonedTime(dateLeft, timezone),
        utcToZonedTime(dateRight, timezone),
      );
};
export const isSameYearTimezone = (
  dateLeft: Date | number,
  dateRight: Date | number,
  timezone: string,
): boolean => {
  return !timezone
    ? isSameYear(dateLeft, dateRight)
    : isSameYear(
        utcToZonedTime(dateLeft, timezone),
        utcToZonedTime(dateRight, timezone),
      );
};

export function setInTimezone(
  utcDate: Date,
  year: number,
  month: number,
  day: number,
  timezone: string,
) {
  if (!timezone) {
    return set(utcDate, { year, month, date: day });
  }

  const zonedDate = utcToZonedTime(utcDate, timezone);
  const newZonedDate = set(zonedDate, { year, month, date: day });
  return zonedTimeToUtc(newZonedDate, timezone);
}
