import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-expanded"]

import { ChipListItem } from '@/shared/types/components';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AppChip from '../AppChip/AppChip.vue';

interface Props {
  items: ChipListItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppExpandableChipList',
  props: {
    items: {}
  },
  setup(__props: any) {

const props = __props;

const listIsExpanded = ref<boolean>(false);

const listIsExpandable = computed<boolean>(() => props.items.length > 2);

const expandCollapseChipLabel = computed<string>(() =>
  listIsExpanded.value ? 'labels.common.collapse' : 'labels.common.expand',
);

function handleExpandCollapseChipClick() {
  listIsExpanded.value = !listIsExpanded.value;
}

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "app-chip-list",
    "data-expanded": listIsExpanded.value
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(AppChip, {
        key: item.label,
        class: "app-chip-list__chip"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(['app-chip-list__chip__label', item?.class])
          }, _toDisplayString(item.label), 3)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (listIsExpandable.value)
      ? (_openBlock(), _createBlock(AppChip, {
          key: 0,
          class: "app-chip-list__expand-collapse-chip",
          clickable: "",
          onClick: handleExpandCollapseChipClick
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_unref(t)(expandCollapseChipLabel.value)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})