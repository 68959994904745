<script lang="ts" setup>
import { computed } from 'vue';
import { AppButtonToggleOption } from '@/shared/types/generic';
import { AppButtonSize } from '@/shared/types/components';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';
import AppField from '../AppField/AppField.vue';
import AppTooltip from '../AppTooltip/AppTooltip.vue';

interface Props {
  options: AppButtonToggleOption[];
  error?: string;
  disabled?: boolean;
  isolatedButtons?: boolean;
  size?: AppButtonSize;
  hideBottomSpace?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  size: 'L',
  error: undefined,
  hideBottomSpace: true,
});

const model = defineModel<string | number>();

const hasError = computed<boolean>(() => {
  return !!props.error && props.error.length > 0;
});
</script>

<template>
  <AppField :error="error" :hide-bottom-space="hideBottomSpace">
    <div
      :class="[
        'app-button-toggle',
        { 'app-button-toggle--isolated': isolatedButtons },
        { 'app-button-toggle--grouped': !isolatedButtons },
      ]"
    >
      <q-btn-toggle
        v-model="model"
        :options="options"
        :ripple="false"
        :toggle-color="null"
        :text-color="null"
        :toggle-text-color="null"
        :class="[
          'app-button-toggle__toggle',
          `app-button-toggle__toggle--${size}`,
          { 'app-button-toggle__toggle--error': hasError },
        ]"
        :disable="disabled"
        unelevated
        data-testid="app-button-toggle"
      >
        <template
          v-for="option in options.filter((opt) => !!opt.slot)"
          :key="option.value"
          #[option.slot]
        >
          <slot :name="option.slot">
            <AppTooltip
              v-if="option.tooltip"
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              {{ option.tooltip }}
            </AppTooltip>
          </slot>
        </template>
      </q-btn-toggle>
    </div>
    <template #error>
      <AppErrorMessage :error="error" />
    </template>
    <slot name="hintSpace" class="hint"></slot>
  </AppField>
</template>

<style scoped lang="scss">
.app-button-toggle {
  position: relative;
}

.app-button-toggle__toggle {
  background-color: $white;
  overflow: hidden;
  width: fit-content;
}

.app-button-toggle__toggle--error :deep(.q-btn) {
  border-color: $red-500 !important;
}

.app-button-toggle__toggle--error :deep(.q-btn):not(:first-child),
.app-button-toggle__toggle--error :deep(.q-btn):not(:last-child) {
  border-top-color: $red-500;
  border-bottom-color: $red-500;
}

.app-button-toggle__toggle :deep(.q-btn) {
  min-height: unset;
  border-radius: 0;
  text-transform: unset;
  padding: 0 16px;
  justify-content: center;
  border: 1px solid $gray-400;
  color: $gray-700 !important;
  background-color: $white;
  transition: all 0.15s ease-in-out;
  font-weight: 700;
}

.app-button-toggle__toggle :deep(.q-hoverable:hover > .q-focus-helper) {
  background: none;
}

.app-button-toggle__toggle--S :deep(.q-btn) {
  height: 32px;
}

.app-button-toggle__toggle--M :deep(.q-btn) {
  height: 40px;
}

.app-button-toggle__toggle--L :deep(.q-btn) {
  height: 48px;
}

.hint {
  min-height: 300px;
}

.app-button-toggle__toggle :deep(.q-btn[aria-pressed='true']) {
  border-color: $gray-700;
  color: $gray-700 !important;
  background-color: $primary-background;
}

.app-button-toggle__toggle :deep(.q-btn:hover:not([disabled])) {
  border-color: $primary-color;
  color: $primary-color !important;
  background-color: $primary-background;
}

.app-button-toggle--isolated {
  .app-button-toggle__toggle {
    gap: 24px;
  }

  .app-button-toggle__toggle :deep(.q-btn) {
    border-radius: 8px;
    border-color: $gray-50;
    box-shadow: $neutral-light-shadow;
  }

  .app-button-toggle__toggle :deep(.q-btn[aria-pressed='true']) {
    border-color: $gray-700;
  }

  .app-button-toggle__toggle :deep(.q-btn:hover:not([disabled])) {
    border-color: $primary-color;
  }
}

.app-button-toggle--grouped {
  .app-button-toggle__toggle :deep(.q-btn) {
    padding: 6px 12px;
    min-width: auto;
    border-color: $gray-100;
    text-transform: capitalize;
  }

  .app-button-toggle__toggle :deep(.q-btn):first-child {
    border-radius: 4px 0px 0px 4px;
  }

  .app-button-toggle__toggle :deep(.q-btn:last-child) {
    border-radius: 0px 4px 4px 0px;
  }

  .app-button-toggle__toggle :deep(.q-btn:not(:last-child)) {
    border-right-width: 0;
  }

  .app-button-toggle__toggle :deep(.q-btn[aria-pressed='true']) {
    border-color: $gray-700;
    border-right-width: 1px;
  }

  .app-button-toggle__toggle :deep(.q-btn[aria-pressed='true'] + .q-btn),
  .app-button-toggle__toggle :deep(.q-btn:hover + .q-btn) {
    border-left-width: 0;
  }

  .app-button-toggle__toggle :deep(.q-btn:hover:not([disabled])) {
    border-color: $primary-color;
    border-right-width: 1px;
  }
}
</style>
